export const USER_ROLES = {
    ADMIN: 'admin',
    PREP_CENTER_ADMIN: 'prep_center_admin'
};

export const pathToNameMap = {
    "/": "Home",
    "/dashboard": "Dashboard",
    "/manage-subscription": "Manage Subscription",
    "/change-subscription": "Change Subscription",
    "/mfa": "Two-Factor Auth",
    "/seller-account": "Seller Account",
    "/amazon/oauth": "Authorization Result",
};

export const DEFAULT_PAGE_SIZE = 12;