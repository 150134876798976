import axios from "axios";
import { serverEndpoint } from "../../util/config";
import { useEffect, useState } from "react";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";
import Box from '@mui/material/Box';
import { Chip } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, useGridApiRef } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FaCaretDown, FaCaretRight, FaCloudDownloadAlt } from "react-icons/fa";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import { Link } from "react-router-dom";
import { IoFilterSharp } from "react-icons/io5";

function ViewReports() {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [reportsData, setReportsData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [startingFrom, setStartingFrom] = useState(0);
    const [noOfDocuments, setNoOfDocuments] = useState(DEFAULT_PAGE_SIZE);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [localDataAvailable, setLocalDataAvailable] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [isFilterExanded, setIsFilterExpanded] = useState(false);
    const apiRef = useGridApiRef();

    const handleFilterClick = () => {
        apiRef.current.showFilterPanel();
    };

    const toggleAccordion = () => {
        setIsFilterExpanded(!isFilterExanded);
    };

    const getReports = async (reset = false) => {
        if (!reset) {
            if (itemsLoading || localDataAvailable) return;
        }

        try {
            setItemsLoading(true);
            const params = {
                startingFrom: startingFrom || 0,
                noOfDocuments: noOfDocuments || DEFAULT_PAGE_SIZE,

            };
            const response = await axios.get(`${serverEndpoint}/async/get-excelsheets`, {
                params: params,
                withCredentials: true,
            });
            const pagination = response?.data?.pagination || {};
            setReportsData(!reset ? (prev) => [...prev, ...response.data.items] : response.data.items);
            setDisplayData(!reset ? (prev) => [...prev, ...response.data.items] : response.data.items);
            setStartingFrom(pagination.startingFrom + pagination.noOfDocuments);
            setNoOfDocuments(pagination.noOfDocuments);
            if (!response.data.lastEvaluatedKey) {
                setLocalDataAvailable(true);
            } else {
                setLocalDataAvailable(false);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getReports();
    }, [page]);

    const getReportById = async () => {
        try {
            setItemsLoading(true);

            const response = await axios.get(`${serverEndpoint}/async/get-excelsheet/${searchQuery}`, { withCredentials: true, });
            setDisplayData([response.data]);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim() === "") {
                setDisplayData([...reportsData]); // Reset to full data if search term is empty
            } else {
                const searchResults = reportsData.filter((item) =>
                    item.sk?.toLowerCase().includes(searchQuery.toLowerCase())
                );

                if (searchResults) {
                    setDisplayData(searchResults);
                } else {
                    getReportById(searchQuery.toLowerCase);
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, reportsData]);

    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        overflowY: 'hidden',
                    },
                    columnHeaders: {
                        fontWeight: 'bold', // Apply bold to the entire header row container
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold', // Ensure individual header text is bold
                        },
                    },
                },
            },
        },
    });

    const columns = [
        {
            field: 'sk',
            headerName: 'Request ID',
            width: 300,
            renderCell: (params) => {
                const requestId = params.row.sk.split('#')[1];
                return (
                    <>
                        {requestId}
                    </>
                );
            },
        },
        {
            field: 'excel_type',
            headerName: 'Type',
            width: 70,
            renderCell: (params) => {
                const type = params.row.excel_type === 'tasks' ? 'Task' : 'Instock';
                return (
                    <>
                        {type}
                    </>
                );
            },
        },
        {
            field: 'excel_processing_status',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => {
                const { excel_processing_status } = params.row;
                let color;
                let displayText;

                if (excel_processing_status === 'PENDING') {
                    displayText = 'Scheduled';
                    color = 'info';
                } else if (excel_processing_status === 'IN_PROGRESS') {
                    displayText = 'Processing';
                    color = 'warning';
                } else if (excel_processing_status === 'FAILED') {
                    displayText = 'Failed';
                    color = 'danger';
                } else if (excel_processing_status === 'COMPLETED') {
                    displayText = 'Completed';
                    color = 'success';
                } else {
                    displayText = 'Unknown';
                    color = 'danger';
                }

                return (
                    <Chip label={displayText} color={color} variant="outlined" />
                );
            },
        },
        {
            field: 'creation_date',
            headerName: 'Creation Date',
            width: 240,
            renderCell: (params) => {
                return (
                    <>{formatIsoDate(params?.row?.creation_date)}</>
                );
            },
        },
        {
            field: 'completion_date',
            headerName: 'Completion Date',
            width: 240,
            editable: false,
            renderCell: (params) => {
                return (
                    <>{params.row.completion_date ? formatIsoDate(params?.row?.completion_date) : ''}</>
                );
            },
        },
        {
            field: 'download',
            headerName: 'Download',
            width: 150,
            editable: false,
            filterable: false,
            renderCell: (params) => {
                return (
                    <>
                        {params.row.excel_processing_status === 'COMPLETED' && (
                            <button className="btn btn-sm btn-success text-white rounded-4" onClick={() => {
                                const link = document.createElement('a');
                                link.href = params.row.excel_url;
                                link.download = 'report.xlsx'; // Specify the file name
                                link.target = '_blank'; // Open in a new tab if needed
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link); // Cleanup
                            }}>
                                <FaCloudDownloadAlt className="mb-1" /> Download
                            </button>
                        )}
                    </>
                );
            }
        }
    ];

    const CustomToolbar = () => (
        <GridToolbarContainer style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <GridToolbarFilterButton /> {/* Filter button */}
        </GridToolbarContainer>
    );

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <section>
            <div className="container p-4">
                <div className="row align-items-center">
                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}
                    {message && (
                        <AutoDismissibleAlert type="success" message={message} />
                    )}
                    <div className="ms-1">
                        {errors.message && (
                            <AutoDismissibleAlert type="danger" message={errors.message} />
                        )}
                        <h2>Reports</h2>
                        <p className="text-secondary mb-2">View & Download Generated Reports of Prepared & Instock Items</p>
                    </div>
                </div>
                
                <div className="row m-1 accordion mt-3" id="accordionExample">
                    {/* Accordion Item 1 */}
                    <div className="accordion-item">
                        <h6 className="accordion-header py-3" id="headingOne">
                            <div className="d-flex align-items-center w-100">
                                <div className="form-check d-flex align-items-center">
                                    <Link
                                        className="text-dark btn-link cursor-pointer text-decoration-none"
                                        data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                        aria-controls="collapseOne" onClick={toggleAccordion}>
                                        {isFilterExanded ? (
                                            <FaCaretDown className="text-dark mb-1" size={18} />
                                        ) : (
                                            <FaCaretRight className="text-dark mb-1" size={18} />
                                        )}
                                        Search & Filter
                                    </Link>
                                </div>
                            </div>
                        </h6>

                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            <hr className='m-0' />
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col">
                                        <label for="exampleFormControlInput1" className="form-label">Search</label>
                                        <input type="text" placeholder="Search by title or Description" value={searchQuery}
                                            className="form-control" id="exampleFormControlInput1" onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <label for="exampleFormControlInput1" className="form-label">Filter By: Request ID, Type, Status, Creation Date, or Completion Date</label>
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col">
                                        <button className="btn btn-outline-primary btn-sm" onClick={handleFilterClick}><IoFilterSharp size={24} className="mb-1" /> FILTERS</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }} className="mt-3 p-3 mx-1 rounded border bg-white">
                    <ThemeProvider theme={theme}>
                        <Box>
                            <DataGrid
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: startingFrom, pageSize: noOfDocuments }, // Set initial state explicitly
                                    },
                                    sorting: {
                                        sortModel: [{ field: "creation_date", sort: "desc" }],
                                    },
                                }}
                                apiRef={apiRef}
                                rows={displayData.slice(page * pageSize, (page + 1) * pageSize)}
                                getRowId={(row) => row.sk}
                                rowCount={localDataAvailable ? displayData.length : -1}
                                columns={columns}
                                disableRowSelectionOnClick
                                showCellVerticalBorder={true}
                                showColumnVerticalBorder={true}
                                pagination
                                paginationMode="server"
                                pageSize={pageSize}
                                page={page} // Controlled page
                                rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
                                onPaginationModelChange={({ page }) => {
                                    handlePageChange(page);
                                }}
                                loading={loading || itemsLoading}
                                sx={{
                                    minHeight: displayData.length === 0 ? 400 : 'auto',
                                    '& .MuiDataGrid-overlay': {
                                        backgroundColor: '#ffffff',
                                    },
                                }}
                                localeText={{
                                    noRowsLabel: "No data available to display",
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                </div>
            </div>
        </section>
    );
}

const formatIsoDate = (isoString) => {
    try {
        const date = new Date(isoString); // Parse the ISO string
        if (isNaN(date)) {
            throw new Error("Invalid date format");
        }
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
        console.error("Error formatting date:", error);
        return "Date Not Available";
    }
};


export default ViewReports;