import { useCallback, useState } from "react";
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import { useSelector } from "react-redux";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";

function AddTask() {
    const userDetails = useSelector((state) => state.userDetails);
    const [formData, setFormData] = useState({
        quantityToPrepare: 0,
        itemToPrepare: [],
    });
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);
    const [loading, setLoading] = useState(false);

    const getListings = async (searchString) => {
        try {
            const response = await axios.get(`${serverEndpoint}/listings/get-listings/${searchString}`, { withCredentials: true });
            const listings = response.data?.documents;
            listings.forEach(item => {
                if (item.bundles) {
                    for (const bundleId in item.bundles) {
                        delete item.bundles[bundleId].image_url;
                    }
                }
            });
            return listings.map(item => ({
                value: item.sk.split('L#')[1],
                label: `[Listing] ${item.title}`,
                title: item.title,
                itemId: item.sk,
                imageKey: item.image_key,
                bundles: item.bundles,
            }));
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again!' });
            return [];
        }
    };

    const getBundles = async (searchString) => {
        try {
            const response = await axios.get(`${serverEndpoint}/bundles/get-bundles/${searchString}`, { withCredentials: true });
            const bundles = response.data?.documents;
            bundles.forEach(item => {
                if (item.listings) {
                    for (const listingId in item.listings) {
                        delete item.listings[listingId].image_url;
                    }
                }

                if (item.components) {
                    for (const componentId in item.components) {
                        delete item.components[componentId].image_url;
                    }
                }
            });
            return bundles.map(item => ({
                value: item.sk.split('B#')[1],
                label: `[Bundle] ${item.title}`,
                title: item.title,
                itemId: item.sk,
                imageKey: item.image_key,
                listings: item.listings,
                components: item.components,
            }));
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again!' });
            return [];
        }
    };

    const fetchOptionsWithDelay = useCallback((inputValue, callback) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(async () => {
            if (!inputValue) return;

            try {
                const [listings, bundles] = await Promise.all([getListings(inputValue), getBundles(inputValue)]);
                const options = [...listings, ...bundles];
                callback(options);
            } catch (error) {
                console.log(error);
                callback([]);
            }
        }, 500);

        setTimeoutId(newTimeoutId);
    }, [timeoutId]);

    const isEmpty = (obj, key) => {
        return !(key in obj) || Object.keys(obj[key]).length === 0;
    };

    const handleChange = (selectedOption) => {
        setErrors({});
        if (selectedOption.itemId.split('#')[0] !== 'L' && isEmpty(selectedOption, 'listings') && isEmpty(selectedOption, 'components') && isEmpty(selectedOption, 'bundles')) {
            setErrors({ itemToPrepare: 'The selected bundle is empty' });
            return;
        }

        setFormData({
            ...formData,
            itemToPrepare: selectedOption,
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.quantityToPrepare === undefined || formData.quantityToPrepare === '') {
            newErrors.quantityToPrepare = "Quantity to Prepare is required.";
            isValid = false;
        } else if (!Number.isInteger(Number(formData.quantityToPrepare)) || Number(formData.quantityToPrepare) < 1) {
            newErrors.quantityToPrepare = "Quantity to Prepare must be a non-negative integer greater than 0.";
            isValid = false;
        }

        if (formData.itemToPrepare === undefined || formData.itemToPrepare.length === 0) {
            newErrors.itemToPrepare = "Please select an item to prepare";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/tasks/create-task`, {
                title: formData.itemToPrepare.title,
                quantity_to_prepare: formData.quantityToPrepare,
                listings: formData.itemToPrepare.listings ? formData.itemToPrepare.listings : {},
                components: formData.itemToPrepare.components ? formData.itemToPrepare.components : {},
                bundles: formData.itemToPrepare.bundles ? formData.itemToPrepare.bundles : {},
                image_key: formData.itemToPrepare.imageKey,
                item_id: formData.itemToPrepare.itemId,
                prep_center_id: userDetails.prepCenterId
            }, { withCredentials: true })
                .then(response => {
                    setMessage('Task Created!');
                    setFormData({
                        quantityToPrepare: 0,
                        itemToPrepare: [],
                    });
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Something went wrong, please try again!" });
                    setLoading(false);
                });
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>Create ToDo Task</h2>
                    <p className="text-secondary mb-2">Add items to your todo list</p>
                    
                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}

                    {message && (
                        <AutoDismissibleAlert type="success" message={message} />
                    )}

                    <form className="p-4 rounded border bg-white">
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="itemToPrepare">
                                Item to Prepare
                            </label>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={fetchOptionsWithDelay}
                                defaultOptions={[]}
                                value={formData.itemToPrepare}
                                onChange={handleChange}
                                placeholder="Search for an Item (Listing or Bundle)..."
                                noOptionsMessage={() => "No Listings or Bundles found"}
                                className={errors.itemToPrepare ? 'is-invalid' : ''}
                                classNamePrefix="react-select"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        '&:hover': {
                                            borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        },
                                    }),
                                }}
                            />
                            <div className="invalid-feedback">{errors?.itemToPrepare}</div>
                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="quantityToPrepare">
                                Quantity to Prepare
                            </label>
                            <input
                                id="quantityToPrepare"
                                type="text"
                                className={errors.quantityToPrepare ? 'form-control is-invalid' : 'form-control'}
                                name="quantityToPrepare"
                                onChange={(e) => setFormData({ ...formData, quantityToPrepare: e.target.value })}
                                value={formData.quantityToPrepare}
                            />
                            <div className="invalid-feedback">{errors?.quantityToPrepare}</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {loading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!loading && (
                                <button className="btn btn-primary ms-auto" type="button" onClick={handleSubmit}>
                                    Add
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddTask;
