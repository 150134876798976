import { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner";
import axios from "axios";
import { serverEndpoint } from "../../../util/config";
import { GoDeviceMobile } from "react-icons/go";
import { QRCodeSVG } from "qrcode.react";
import AutoDismissibleAlert from "../../../components/AutoDismissibleAlert";

function MultiFactorAuthenticationSetting({ userDetails }) {
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [mfaEnabled, setMfaEnabled] = useState(null);
    const [configureMfa, setConfigureMfa] = useState(false);
    const [verificationCode, setVerificationCode] = useState('');
    const [secretKey, setSecretKey] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    const appName = 'JBLPrepMaster';

    const fetchMfaSettingStatus = async () => {
        setError(null);
        axios.post(`${serverEndpoint}/auth/settings`, {}, { withCredentials: true })
            .then(response => {
                if (response.data?.status === 'NOT_ENABLED') {
                    setMfaEnabled(false);
                } else if (response.data?.status === 'ENABLED') {
                    setMfaEnabled(true);
                }
                setLoading(false);
            }).catch(error => {
                console.log(error);
                setError("Something went wrong while fetching two-factor authentication settings, please try again!");
                setLoading(false);
            });
    };

    const handleConfigureMfa = async () => {
        setFormLoading(true);
        setMessage(null);
        setError(null);
        setVerificationCode('');
        if (!configureMfa) {
            axios.post(`${serverEndpoint}/auth/enable-mfa`, {}, { withCredentials: true })
                .then(response => {
                    setSecretKey(response.data.SecretCode);
                    setConfigureMfa(!configureMfa);
                    setFormLoading(false);
                }).catch(error => {
                    console.log(error);
                    setFormLoading(false);
                    setError("Something went wrong, please try again!");
                });
        } else {
            setConfigureMfa(!configureMfa);
            setFormLoading(false);
        }
    };

    const handleDisableMfa = async () => {
        setFormLoading(true);
        setMessage(null);
        setError(null);
        setVerificationCode('');
        axios.post(`${serverEndpoint}/auth/disable-mfa`, {}, { withCredentials: true })
            .then(() => {
                setMessage("Two-factor authentication has been disabled.");
                setMfaEnabled(false);
                setFormLoading(false);
            }).catch(error => {
                setError("Error disabling MFA, please try again.");
                setFormLoading(false);
            });
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;

        // Allow only numbers and limit length to 6
        if (/^\d*$/.test(inputValue) && inputValue.length <= 6) {
            setVerificationCode(inputValue);
        }
    };

    const handleSubmit = async (e) => {
        setFormLoading(true);
        e.preventDefault();
        setMessage(null);
        setError(null);
        if (verificationCode) {
            axios.post(`${serverEndpoint}/auth/verify-mfa`, {
                username: userDetails.displayEmail,
                totpCode: verificationCode
            }, { withCredentials: true })
                .then(response => {
                    setMessage("You have successfully enabled two-factor authentication!");
                    setFormLoading(false);
                    setConfigureMfa(false);
                    setMfaEnabled(true);
                }).catch(error => {
                    console.log(error);
                    if (error.response?.data?.error === "Code mismatch") {
                        setError("Authentication failed, invalid code");
                    } else {
                        setError("Something went wrong");
                    }
                    setFormLoading(false);
                });
        } else {
            setError("Please provide verification code!");
            setFormLoading(false);
        }
    };

    useEffect(() => {
        fetchMfaSettingStatus();
    }, []);

    if (loading) {
        return <Spinner />
    }

    if (mfaEnabled === null) {
        return (
            <section className="h-100">
                <div className="container h-100">
                    <div className="row justify-content-sm-center h-100 mt-4 bg-white p-2 rounded border">
                        <h2 className="">Two-factor Authentication</h2>
                        <div className="col mb-2">
                            {error && (
                                <AutoDismissibleAlert type="danger" message={error} />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (

        <section className="h-100">
            <div className="container h-100">
                <div className="row justify-content-sm-center h-100 mt-4">
                    <h2 className="">Two-factor Authentication</h2>
                    <div className="col mb-2">
                        {message && (
                            <AutoDismissibleAlert type="success" message={message} />
                        )}
                        {error && (
                            <AutoDismissibleAlert type="danger" message={error} />
                        )}
                        <div className="card mt-2 p-2">
                            {/* <div className="card-header">Two-factor Method</div> */}
                            <ul className="list-group list-group-flush mt-2">
                                <li className="list-group-item">
                                    {!configureMfa && (
                                        <div className="row">
                                            <div className="col-md-9 col-9">
                                                <div className="me-auto">
                                                    <div className="fw-bold mb-1">
                                                        <GoDeviceMobile size={21} className="mb-1" /> Authenticator App
                                                    </div>
                                                    <small className="text-muted">
                                                        Use an authentication app or browser extension to get two-factor authentication codes when prompted.
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-12 text-md-end text-center mt-2 mt-md-0">
                                                {formLoading ? (
                                                    <button className="btn btn-sm btn-outline-primary rounded-pill" type="button" disabled>
                                                        <span
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    </button>
                                                ) : (
                                                    <>
                                                        {!mfaEnabled ? (
                                                            <button className="btn btn-sm btn-outline-primary rounded-pill" onClick={handleConfigureMfa}>
                                                                Add
                                                            </button>
                                                        ) : (
                                                            <button className="btn btn-sm btn-outline-primary rounded-pill" onClick={handleDisableMfa}>
                                                                Remove
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    {configureMfa && (
                                        <>
                                            <div className="row">
                                                <div className="col-md-12 col-12">
                                                    <div className="me-auto">
                                                        <div className="fw-bold mb-1">
                                                            <GoDeviceMobile size={21} className="mb-1" /> Authenticator App
                                                        </div>
                                                        <small className="text-muted">
                                                            Authenticator apps and browser extensions like
                                                            <a href="https://support.1password.com/one-time-passwords/" target="_blank">1Password</a>,
                                                            <a href="https://authy.com/guides/github/" target="_blank"> Authy</a>,
                                                            <a href="https://www.microsoft.com/en-us/account/authenticator/" target="_blank"> Microsoft Authenticator</a>
                                                            , etc.
                                                            generate one-time passwords that are used as a second factor to
                                                            verify your identity when prompted during sign-in.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-4">
                                                <div className="col-md-12 col-12">
                                                    <div className="me-auto">
                                                        <div className="fw-bold mb-1">
                                                            Scan the QR code
                                                        </div>
                                                        <small className="text-muted">
                                                            Use an authenticator app or browser extension to scan.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-md-12 col-12">
                                                    <div className="me-auto">
                                                        <QRCodeSVG value={`otpauth://totp/${userDetails.username}?secret=${secretKey}&issuer=${appName}`} size={140} className="p-2 border rounded" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-md-12 col-12">
                                                    <small className="text-muted">
                                                        Unable to scan? You can manually configure your authenticator app using this secret key: <strong>{secretKey}</strong>
                                                    </small>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-md-12 col-12">
                                                    <div className="fw-bold mb-1">
                                                        Verify the code from the app
                                                    </div>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row mt-2">
                                                        <div className="col-8 col-md-5 col-lg-3">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    maxLength={6}
                                                                    minLength={6}
                                                                    placeholder="XXXXXX"
                                                                    autoFocus
                                                                    value={verificationCode}
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-3">
                                                                {formLoading && (
                                                                    <button className="btn btn-success btn mt-2" type="button" disabled="">
                                                                        <span
                                                                            className="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>
                                                                )}

                                                                {!formLoading && (
                                                                    <div className="d-flex align-items-center mt-2">
                                                                        <button className="btn btn-success border" type="submit">
                                                                            Verify
                                                                        </button>
                                                                        <button className="btn btn-light border mx-1" type="button" onClick={handleConfigureMfa}>
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                )}


                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </>
                                    )}

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MultiFactorAuthenticationSetting;