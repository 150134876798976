import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint } from "../../util/config";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorComponent from "../../components/Error";
import { hasPermission } from "../../util/permissionsEvaluator";
import { DEFAULT_PAGE_SIZE, USER_ROLES } from "../../util/constants";
import ConfirmDialog from "../../components/ConfirmDialog";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import Select from 'react-select';
import { FaCaretRight, FaCaretDown } from "react-icons/fa";

function ViewInboundItems({ userDetails }) {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsLoading, setItemsLoading] = useState(false);
    const [moreDataAvailable, setMoreDataAvailable] = useState(false);
    const [startingFrom, setStartingFrom] = useState(null);
    const [noOfDocuments, setNoOfDocuments] = useState(null);
    const [isFilterExanded, setIsFilterExpanded] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [quantityMap, setQuantityMap] = useState({});
    const [isBulkUpdateInProgress, setIsBulkUpdateInProgress] = useState(false);
    const BATCH_SIZE = 20;

    const toggleAccordion = () => {
        setIsFilterExpanded(!isFilterExanded);
    };

    const getInboundItems = async (reset = false) => {
        if (itemsLoading) return;

        try {
            setItemsLoading(true);
            const params = {
                startingFrom: reset ? 0 : startingFrom,
                noOfDocuments: noOfDocuments || DEFAULT_PAGE_SIZE,
            };

            let response;
            if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
                if (selectedPrepMasterAccount !== null) {
                    params.email = selectedPrepMasterAccount.value;
                    response = await axios.get(`${serverEndpoint}/inbound/items/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                } else {
                    response = await axios.get(`${serverEndpoint}/inbound/prep-center/${userDetails.masterAccountId}/items/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                }
            } else {
                params.email = userDetails.username;
                response = await axios.get(`${serverEndpoint}/inbound/items/${searchQuery}`, {
                    params: params,
                    withCredentials: true,
                });
            }

            const newDocuments = response.data.documents.map(item => ({
                ...item,
                url: item.type === "Listing" ? `/listing/${item.itemId}` : `/component/${item.itemId}`
            }));
            const pagination = response?.data?.pagination || {};
            if (pagination.currentPage === pagination.totalPages || pagination.currentPage > pagination.totalPages) {
                setMoreDataAvailable(false);
            } else {
                setMoreDataAvailable(true);
            }
            setDisplayData((prev) => reset ? newDocuments : [...prev, ...newDocuments]);
            setStartingFrom(pagination.startingFrom + pagination.noOfDocuments);
            setNoOfDocuments(pagination.noOfDocuments);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
        } finally {
            setLoading(false);
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getInboundItems();
    }, []);

    const [selectedPrepMasterAccount, setSelectedPrepMasterAccount] = useState(null);
    const [prepMasterAccountsLoading, setPrepMasterAccountsLoading] = useState(true);
    const [prepMasterAccounts, setPrepMasterAccounts] = useState([]);

    const handlePrepMasterAccountChange = async (option) => {
        setSelectedPrepMasterAccount(option);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getInboundItems(true);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, selectedPrepMasterAccount]);

    const getPrepMasterAccounts = async () => {
        try {
            const response = await axios.post(`${serverEndpoint}/user/get-users`, {
                master_account_id: userDetails.masterAccountId
            }, { withCredentials: true });
            const accounts = response?.data?.users?.map((account) => ({
                value: account.email,
                label: account.email,
            }));
            setPrepMasterAccounts(accounts);
        } catch (error) {
            console.log(error);
            setErrors({ message: "Unable to fetch Prep Master Accounts for Filter" });
        } finally {
            setPrepMasterAccountsLoading(false);
        }
    };

    useEffect(() => {
        if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
            getPrepMasterAccounts();
        } else {
            setPrepMasterAccountsLoading(false);
        }
    }, []);

    const [selectedInbountItem, setSelectedInboundItem] = useState(null);
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [itemDeleteLoading, setItemDeleteLoading] = useState(false);

    const handleDelete = (inboundItem) => {
        if (!hasPermission(userDetails, "Inbound", "Delete")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setSelectedInboundItem(inboundItem);
        setShowConfirmDeleteModal(true);
    };

    const handleConfirmDeleteModalClose = (confirmed) => {
        if (confirmed) {
            setItemDeleteLoading(true);
            const params = {
                type: selectedInbountItem.type
            };

            if (userDetails.prepCenterId) {
                params.prep_center_id = userDetails.prepCenterId;
            }
            axios.delete(`${serverEndpoint}/inbound/delete/${selectedInbountItem.itemId}`, {
                params: params,
                withCredentials: true
            })
                .then(response => {
                    setDisplayData(prevItems =>
                        prevItems.filter(item => item.itemId !== selectedInbountItem.itemId)
                    );
                    setShowConfirmDeleteModal(false);
                    setItemDeleteLoading(false);
                }).catch(error => {
                    setErrors({ message: "Unable to delete bundle, try again!" });
                    setShowConfirmDeleteModal(false);
                    setItemDeleteLoading(false);
                });
        } else {
            setShowConfirmDeleteModal(false);
        }
    };

    const [markArrivedLoading, setMarkArrivedLoading] = useState(false);
    const [showMarkArrivedModal, setShowMarkArrivedModal] = useState(false);
    const [markArrivedFormData, setMarkArrivedFormData] = useState({
        qtyArrived: 0
    });

    const handleClose = () => setShowMarkArrivedModal(false);
    const handleShow = (inboundItem) => {
        if (!hasPermission(userDetails, "In-stock", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setErrors({});
        setMarkArrivedFormData({
            email: userDetails.role === USER_ROLES.PREP_CENTER_ADMIN ? inboundItem.pk.split('#')[0] : userDetails.username,
            qtyArrived: inboundItem.inbound_qty,
            itemId: inboundItem.itemId,
            itemType: inboundItem.type,
            cog: inboundItem.cog || 0,
            originalQtyArriving: inboundItem.inbound_qty
        });
        setShowMarkArrivedModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMarkArrivedFormData({
            ...markArrivedFormData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (markArrivedFormData.qtyArrived <= 0) {
            setErrors({
                qtyArrived: 'Please enter a valid Quantity'
            });
            return;
        }

        if (markArrivedFormData.qtyArrived > markArrivedFormData.originalQtyArriving) {
            setErrors({
                qtyArrived: 'Quantity arrived cannot be greater than quantity inbound'
            });
            return;
        }

        setMarkArrivedLoading(true);

        try {
            const requestBody = {
                email: markArrivedFormData.email,
                itemId: markArrivedFormData.itemId,
                type: markArrivedFormData.itemType,
                qtyArrived: markArrivedFormData.qtyArrived,
                cog: markArrivedFormData.cog
            };

            if (userDetails.prepCenterId) {
                requestBody.prep_center_id = userDetails.prepCenterId;
            }

            const response = await axios.post(`${serverEndpoint}/inbound/mark-item-arrived`, requestBody, { withCredentials: true });
            if (response.data.newInboundQty > 0) {
                setDisplayData(prevItems =>
                    prevItems.map(item =>
                        item.itemId === markArrivedFormData.itemId
                            ? { ...item, inbound_qty: response.data.newInboundQty }
                            : item
                    )
                );
            } else if (response.data.newInboundQty <= 0) {
                setDisplayData(prevItems =>
                    prevItems.filter(item => item.itemId !== markArrivedFormData.itemId)
                );
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again' });
            setLoading(false);
        }

        setMarkArrivedLoading(false);
        handleClose();
    };

    const [showTrackingDetailsModal, setShowTrackingDetailsModal] = useState(false);
    const [selectedTrackingDetails, setSelectedTrackingDetails] = useState([]);

    const handleTrackingDetailsModalClose = () => setShowTrackingDetailsModal(false);
    const handleTrackingDetailsShow = (trackingDetails) => {
        setSelectedTrackingDetails(trackingDetails);
        setShowTrackingDetailsModal(true);
    }

    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }


    const handleButtonClick = async () => {
        if (selectedItems.length === 0) {
            //setIsBulkPrepare(true);
            setErrors({ message: 'Please select at least one item to proceed!' });
            return;
        }

        try {
            setIsBulkUpdateInProgress(true);
            const chunks = [];
            for (let i = 0; i < selectedItems.length; i += BATCH_SIZE) {
                chunks.push(selectedItems.slice(i, i + BATCH_SIZE));
            }

            let successCount = 0;
            for (let chunk of chunks) {
                const payload = chunk.map((selectedItem) => {
                    let updatedQuantityToPrepare = selectedItem.quantity_to_prepare - selectedItem.updated_quantity_to_prepare;
                    updatedQuantityToPrepare = updatedQuantityToPrepare < 0 ? 0 : updatedQuantityToPrepare;
                    return {
                        email_id: userDetails.role === USER_ROLES.PREP_CENTER_ADMIN 
                            ? selectedItem.email_id 
                            : userDetails.username,
                        item_id: selectedItem.item_id,
                        type: selectedItem.type,
                        qtyArrived: selectedItem.qtyArrived || "1",
                        cog: selectedItem.cog || 0
                    };
                });
                try {
                    await axios.post(
                        `${serverEndpoint}/inbound/mark-items-arrived`,
                        payload,
                        { withCredentials: true }
                    );
                    successCount += payload.length;
                } catch (error) {
                    console.log(error);
                    console.log('Continue with rest of the batches');
                }
            }

            if (successCount === 0) {
                setErrors({ message: 'We were unable to mark as arrived at the moment. Please try again.' })
            } else if (successCount === selectedItems.length) {
                setMessage('Bulk prepare request succeeded!');
            } else {
                setErrors({ message: 'We were not able to mark as arrived some of the Items at the moment. Please try again.' })
            }
        } catch (error) {
            console.error('Unable to update mark as arrived, try again!', error);
            setErrors({ message: 'Unable to update mark as arrived, try again!' });
        } finally {
            await getInboundItems(true);
            setIsBulkUpdateInProgress(false);
        }
    };

    const handleCheckboxChange = (inboundItem) => {
        setSelectedItems((prevSelected) =>
            prevSelected.some(item => item.item_id === inboundItem.itemId)
                ? prevSelected.filter((item) => item.item_id !== inboundItem.itemId) // Remove if already selected
                : [...prevSelected, { // Add new item dynamically
                    email_id: userDetails.role === USER_ROLES.PREP_CENTER_ADMIN ? inboundItem.pk.split('#')[0] : userDetails.username,
                    item_id: inboundItem?.itemId,
                    type: inboundItem?.type,
                    qtyArrived: inboundItem?.inbound_qty || "1",
                    cog: inboundItem?.cog || 0,
                }]
        );
    };
    const handleQuantityChange = (itemId, value) => {
        setQuantityMap(prev => ({ ...prev, [itemId]: value }));
        setSelectedItems(prevSelected =>
            prevSelected.map(item =>
                item.item_id === itemId ? { ...item, qtyArrived: value } : item
            )
        );
    };

    return (
        <>
            <section>
                <div className="container p-4">
                    <div className="row align-items-center ms-1">
                        {errors.message && (
                            <AutoDismissibleAlert type='danger' message={errors.message} />
                        )}

                        {/* Left Section: Title and Subtitle */}
                        <div className="col">
                            <h2 className="mb-0">Inbound Items</h2>
                            <p className="text-secondary mb-0">View all your inbound items including Components and Listings</p>
                        </div>

                        {/* Right Section: Button */}
                        <div className="col-auto">
                            {isBulkUpdateInProgress && (
                                <button className="btn btn-primary btn-sm" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!isBulkUpdateInProgress && (
                                <button
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={handleButtonClick}
                                    disabled={!selectedItems?.length > 0}
                                >
                                    Bulk Prepare
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="row m-1 accordion mt-3" id="accordionExample">
                        {/* Accordion Item 1 */}
                        <div className="accordion-item">
                            <h6 className="accordion-header py-3" id="headingOne">
                                <div className="d-flex align-items-center w-100">
                                    <div className="form-check d-flex align-items-center">
                                        <Link
                                            className="text-dark btn-link cursor-pointer text-decoration-none"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                            aria-controls="collapseOne" onClick={toggleAccordion}>
                                            {isFilterExanded ? (
                                                <FaCaretDown className="text-dark mb-1" size={18} />
                                            ) : (
                                                <FaCaretRight className="text-dark mb-1" size={18} />
                                            )}
                                            Search & Filter
                                        </Link>
                                    </div>
                                </div>
                            </h6>

                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <hr className='m-0' />
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col">
                                            <label for="exampleFormControlInput1" className="form-label">Search</label>
                                            <input type="text" placeholder="Search by title or Description" value={searchQuery}
                                                className="form-control" id="exampleFormControlInput1" onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                        <div className="row mt-4">
                                            <div className="col">
                                                <label for="exampleFormControlInput1" className="form-label">Filter By: Prep Master Accounts</label>
                                                <Select
                                                    options={prepMasterAccounts}
                                                    isClearable={true}
                                                    placeholder="Select"
                                                    isLoading={itemsLoading || prepMasterAccountsLoading}
                                                    isDisabled={itemsLoading || prepMasterAccountsLoading}
                                                    value={selectedPrepMasterAccount}
                                                    onChange={handlePrepMasterAccountChange}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Row className="mt-3 text-center p-2 bg-white border rounded m-1">
                        {displayData?.length === 0 && !itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <p>No results found</p>
                            </div>
                        )}

                        {displayData.map((inboundItem) => (
                            <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={inboundItem.itemId}>
                                <Card className="position-relative h-100 d-flex flex-column">
                                    <div className="position-absolute top-0 start-0 m-2">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={selectedItems.some(item => item.item_id === inboundItem.itemId)}
                                            onChange={() => handleCheckboxChange(inboundItem)}
                                        />
                                    </div>

                                    <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                        <Link to={inboundItem.url} >
                                            <img src={inboundItem.image_url ? inboundItem.image_url : '/images/default-image.jpg'} alt="..." className="rounded"
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                    e.target.src = "/images/default-image.jpg";
                                                }}
                                                style={{
                                                    maxHeight: '210px',
                                                    width: '100%',
                                                    objectFit: 'cover'
                                                }} />
                                        </Link>
                                    </Card.Body>
                                    <Card.Body className="flex-grow-1 pt-0">
                                        <Link to={inboundItem.url} className="text-body text-decoration-none hover:text-primary">
                                            <h5 className="w-100" title={inboundItem.title}>
                                                {inboundItem.title.length > 20 ? `${inboundItem.title.substring(0, 20)}...` : inboundItem.title}
                                            </h5>
                                        </Link>
                                        {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                            <p className="text-muted mb-0"><strong>{inboundItem.pk.split('#')[0]}</strong></p>
                                        )}
                                        {inboundItem.tracking_details && (
                                            <Link className="btn btn-link" onClick={() => handleTrackingDetailsShow(inboundItem.tracking_details)}>Tracking Details</Link>
                                        )}
                                    </Card.Body>
                                    {(hasPermission(userDetails, "In-stock", "Add") || hasPermission(userDetails, "Inbound", "Delete")) && (
                                        <div className="d-flex justify-content-center mb-4">
                                            {hasPermission(userDetails, "In-stock", "Add") && !selectedItems.some(item => item.item_id === inboundItem.itemId) && (
                                                <div className="mx-2">
                                                    <button className="btn btn-sm btn-outline-success" onClick={() => handleShow(inboundItem)}>
                                                        <span className="badge bg-secondary mx-1">{inboundItem.inbound_qty}</span> Mark Arrived
                                                    </button>
                                                </div>
                                            )}
                                            {/* Show quantity input when checkbox is checked */}
                                            {selectedItems.some(item => item.item_id === inboundItem.itemId) && (
                                                <input
                                                    type="number"
                                                    min="1"
                                                    value={quantityMap[inboundItem.itemId] || inboundItem?.inbound_qty || "1"}
                                                    onChange={(e) => handleQuantityChange(inboundItem.itemId, e.target.value)}
                                                    style={{ lineHeight: "1.2" }}
                                                    className="form-control leading-none mx-2"
                                                />
                                            )}
                                            {hasPermission(userDetails, "Inbound", "Delete") && (
                                                <div className="mx-2">
                                                    <button className="btn btn-sm btn-outline-danger" onClick={() => handleDelete(inboundItem)}>
                                                        Delete
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Col className="mt-5">
                        {moreDataAvailable && (
                            <div className="row justify-content-center mt-4">
                                <div className="col-auto">

                                    {itemsLoading ? (
                                        <button className="btn btn-primary" type="button" disabled="">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    ) : (
                                        <button onClick={() => getInboundItems()} className="btn btn-primary">
                                            Load More
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </Col>
                </div>
            </section>

            <Modal show={showMarkArrivedModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Arrived Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="qtyArrived">
                                Quantity Arrived
                            </label>
                            <input id="qtyArrived" type="number" className={errors?.qtyArrived ? 'form-control is-invalid' : 'form-control'}
                                name="qtyArrived" value={markArrivedFormData.qtyArrived} required autoFocus onChange={handleChange}
                            />
                            <div className="invalid-feedback">Please provide a valid Quantity</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {markArrivedLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!markArrivedLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showTrackingDetailsModal} onHide={handleTrackingDetailsModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Tracking Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-striped">
                        <thead className="table">
                            <tr>
                                <th>#</th>
                                <th>Tracking Number</th>
                                <th>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedTrackingDetails.length > 0 ? (
                                selectedTrackingDetails.map((item, index) => (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.trackingNumber}</td>
                                        <td>{item.quantity}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3" className="text-center">
                                        No tracking details available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>

            <ConfirmDialog show={showConfirmDeleteModal} handleClose={handleConfirmDeleteModalClose}
                title="Delete Inbound Item Confirmation"
                message="Are you sure you want to delete the Inbound Item?"
            />
        </>
    );
}

export default ViewInboundItems;
