import axios from 'axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { serverEndpoint } from '../../util/config';
import { useDispatch } from "react-redux";
import AutoDismissibleAlert from '../../components/AutoDismissibleAlert';

function Customization() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector((state) => state.userDetails);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [formData, setFormData] = useState({
        redesignedDashboardEnabled: userDetails.featureFlags.dashboard === 'new',
    });
    const [isDashboardSettingUpdating, setIsDashboardSettingUpdating] = useState(false);
    const [isDashboardPreviewExpanded, setIsDashboardPreviewExpanded] = useState(false);

    const handleRedesignedDashboardCheckboxChange = (event) => {
        setFormData({
            ...formData,
            redesignedDashboardEnabled: event.target.checked
        });
    };

    const handleDashboardPreferenceSubmit = async () => {
        const isRedesignedDashboardAlreadyEnabled = userDetails.featureFlags.dashboard === 'new';
        if (isRedesignedDashboardAlreadyEnabled !== formData.redesignedDashboardEnabled) {
            try {
                setIsDashboardSettingUpdating(true);
                await axios.post(`${serverEndpoint}/feature-flags/update`, {
                    updated_feature_key: 'dashboard',
                    updated_feature_value: formData.redesignedDashboardEnabled ? 'new' : 'old'
                }, { withCredentials: true });
                userDetails.featureFlags.dashboard = formData.redesignedDashboardEnabled ? 'new' : 'old';
                dispatch({ type: 'setUserDetails', userDetails: userDetails });
                setMessage('Customization preference updated!');
                navigate('/dashboard');
            } catch (error) {
                setErrors({ message: 'Unable to save your customization preference, please try again' });
                console.log(error);
            } finally {
                setIsDashboardSettingUpdating(false);
            }
        }
    };

    const toggleAccordion = () => {
        setIsDashboardPreviewExpanded(!isDashboardPreviewExpanded);
    };

    return (
        <section>
            <div className="container">
                <div className="row align-items-center">
                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}
                    {message && (
                        <AutoDismissibleAlert type="success" message={message} />
                    )}
                    {/* Left Section: Title and Subtitle */}
                    <div className="col">
                        <h2 className="mb-0">Customization</h2>
                        <p className="text-secondary mb-0">Manage and personalize your Prep Master Account</p>
                    </div>


                </div>
                <div className="accordion rounded mt-3" id="accordionExample">
                    {/* Accordion Item 1 */}
                    <div className="accordion-item">
                        <h6 className="accordion-header p-3" id="headingOne">
                            <div className="d-flex align-items-center w-100">
                                <div className="form-check d-flex align-items-center">
                                    <input
                                        type="checkbox"
                                        className="form-check-input mb-2"
                                        id="redesigned_dashboard"
                                        checked={formData.redesignedDashboardEnabled}
                                        onChange={handleRedesignedDashboardCheckboxChange}
                                    />
                                    <label
                                        className="form-check-label ms-2 position-relative"
                                        htmlFor="redesigned_dashboard"
                                    >
                                        Enable Redesigned Dashboard
                                        <span className="badge text-secondary">
                                            <Link
                                                className="p-0"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne"
                                                aria-expanded="false"
                                                aria-controls="collapseOne"
                                                onClick={toggleAccordion}
                                            >
                                                {isDashboardPreviewExpanded ? "Close" : "Preview"}
                                            </Link>
                                        </span>
                                    </label>
                                </div>

                                <div className="ms-auto">
                                    {isDashboardSettingUpdating && (
                                        <button className="btn btn-primary rounded-5 btn-sm" type="button" disabled="">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    )}

                                    {!isDashboardSettingUpdating && (
                                        <button
                                            className="btn btn-primary rounded-5 btn-sm"
                                            type="button"
                                            onClick={handleDashboardPreferenceSubmit}
                                        >
                                            Save
                                        </button>
                                    )}
                                </div>
                            </div>
                        </h6>

                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            <hr className='m-0' />
                            <div className="accordion-body text-center">
                                <div className="row">
                                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                                        <figure>
                                            <img
                                                src="/images/dashboard_preview_old.png"
                                                alt="..."
                                                className="img-fluid rounded"
                                            />
                                            <figcaption class="mt-2">Classic Dashboard</figcaption>
                                        </figure>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <figure>
                                            <img
                                                src="/images/dashboard_preview_new.png"
                                                alt="..."
                                                className="img-fluid rounded"
                                            />
                                            <figcaption class="mt-2">Redesigned Dashboard</figcaption>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

export default Customization;