import Box from '@mui/material/Box';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { useEffect, useState } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import Spinner from '../../../components/Spinner';
import { serverEndpoint } from "../../../util/config";
import { MdDelete } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import ConfirmDialog from '../../../components/ConfirmDialog';
import { Tooltip } from '@mui/material';
import UpdateUser from '../../dashboard/user/UpdateUser';
import AutoDismissibleAlert from '../../../components/AutoDismissibleAlert';

function ViewPrepMasterUsers({ userDetails }) {
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        overflowY: 'hidden',
                    },
                    columnHeaders: {
                        fontWeight: 'bold', // Apply bold to the entire header row container
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold', // Ensure individual header text is bold
                        },
                    },
                },
            },
        },
    });

    const [user, setUser] = useState({});
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const handleDeleteUserModalClose = (confirmed) => {
        setShowDeleteUserModal(false);
        if (confirmed) {
            setLoading(true);
            axios.post(`${serverEndpoint}/user/delete-user`, {
                username: user.email
            }, { withCredentials: true })
                .then(response => {
                    const updatedUsers = users.filter(u => u.email !== user.email);
                    setUsers(updatedUsers);
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Unable to delete user, try again!" });
                    setLoading(false);
                });
        }
    };
    const handleDeleteUserModalShow = (user) => {
        setUser(user);
        setShowDeleteUserModal(true);
    };

    const handleUpdateUserAction = (user) => {
        setUser(user);
        setIsUpdateMode(true);
    };

    const handleUserUpdate = (newUserData) => {
        setUsers(prevUsers => {
            const updatedUsers = prevUsers.map(u =>
                u.email === newUserData.email ? { ...u, ...newUserData } : u
            );
            return updatedUsers;
        });
        setIsUpdateMode(false);
    };

    const handleResendTemporaryPassword = (email) => {
        setMessage('Re-sending temporary password...');
        axios.post(`${serverEndpoint}/user/resend-password`, {
            username: email
        }, { withCredentials: true })
            .then(response => {
                setMessage(`Temporary password sent to ${email}`);
            }).catch(error => {
                console.log(error);
                setMessage(null);
                if (error?.response?.data?.name === "UnsupportedUserStateException") {
                    setErrors({ message: `Resending is not possible as the password has already been reset. Please ask the user to use the "Forgot Password" feature to regain access or contact us for assistance.` });
                } else {
                    setErrors({ message: "Unable to resend temporary password" });
                }
            });
    };

    const fetchUsers = async () => {
        axios.post(`${serverEndpoint}/user/get-users`, {
            master_account_id: userDetails.masterAccountId
        }, { withCredentials: true })
            .then(response => {
                setUsers(response.data.users);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                if (error.response?.data?.error === 'User already exists') {
                    setErrors({ message: "An account already exists with the provided email." });
                } else {
                    setErrors({ message: "Something went wrong, please try again!" });
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    if (loading) {
        return <Spinner />
    }

    const columns = [
        { field: 'id', headerName: 'User ID', width: 300 },
        {
            field: 'email',
            headerName: 'Email',
            width: 350,
            editable: false
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 150,
            editable: false,
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 110,
            editable: false,
            renderCell: (params) => (
                <div>
                    {/* <Tooltip title="Edit" placement="top-start">
                        <MdEdit size={24} className="mx-1" onClick={() => handleUpdateUserAction(params.row)} />
                    </Tooltip> */}
                    <Tooltip title="Delete" arrow>
                        <MdDelete size={24} className="mx-1" onClick={() => handleDeleteUserModalShow(params.row)} />
                    </Tooltip>
                    <Tooltip title="Resend Temporary Password" arrow>
                        <MdEmail size={24} className="mx-1" onClick={() => handleResendTemporaryPassword(params.row.email)} />
                    </Tooltip>
                </div>
            )
        },
    ];

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    {!isUpdateMode && (
                        <>
                            <h2>View Accounts</h2>
                            <p className="text-secondary mb-2">View Prep Master Accounts</p>
                            {message && (
                                <AutoDismissibleAlert type="success" message={message} />
                            )}

                            {errors.message && (
                                <AutoDismissibleAlert type="danger" message={errors.message} />
                            )}

                            {users && (
                                <div style={{ display: 'flex', flexDirection: 'column' }} className="mt-3 p-3 rounded border bg-white">
                                    <ThemeProvider theme={theme}>
                                        <Box>
                                            <DataGrid
                                                initialState={{
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            // Hide columns status and traderName, the other columns will remain visible
                                                            id: false
                                                        },
                                                    },
                                                }}
                                                rows={users}
                                                columns={columns}
                                                disableRowSelectionOnClick
                                                slots={{
                                                    toolbar: GridToolbarQuickFilter,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </ThemeProvider>
                                </div>
                            )}
                        </>
                    )}

                    {isUpdateMode && (
                        <>
                            <CCardHeader>
                                <strong>Update User</strong>
                            </CCardHeader>
                            <CCardBody>
                                {errors.message && (
                                    <AutoDismissibleAlert type="danger" message={errors.message} />
                                )}

                                {users && (
                                    <UpdateUser userDetails={user} updateUserDetails={handleUserUpdate} />
                                )}
                            </CCardBody>
                        </>
                    )}
                </div>
            </div>

            {/* Delete user Modal */}
            <ConfirmDialog show={showDeleteUserModal} handleClose={handleDeleteUserModalClose}
                title="Delete User Confirmation"
                message="Are you sure you want to delete this user?"
            />
        </div >
    );
}

export default ViewPrepMasterUsers;