import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Container, Nav, Row, Form } from "react-bootstrap";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa6";
import { serverEndpoint } from "../../util/config";
import axios from "axios";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from "react-router-dom";
import Spinner from "../../components/Spinner";
import ErrorComponent from "../../components/Error";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import { USER_ROLES } from "../../util/constants";

const PricingItem = ({ pricing, initiatePayment, loading }) => (
    <div className="active rounded-4 shadow-lg p-3 p-lg-5"
    >
        <h3>{pricing.planTitle}</h3>
        <div className="mb-3">
            {(pricing.discountPrice || pricing.discountPrice === 0) ? (
                <>
                    <span className="fs-5 text-muted fw-bold text-decoration-line-through">£{pricing.price}</span>
                    <span className="ms-2 fs-3 fw-bold">£{pricing.discountPrice}</span>
                    <span className="ms-2 opacity-50">{pricing.timeline}</span>
                </>
            ) : (
                <>
                    <span className="fs-3 fw-bold">£{pricing.price}</span>
                    <span className="ms-2 opacity-50">£{pricing.timeline}</span>
                </>
            )}
        </div>
        <p className="mb-3 opacity-50">
            {pricing.description}
        </p>
        <Nav className="flex-column">
            {pricing.features.map((feature, i) => (
                <li
                    className={classNames({
                        "mb-3": pricing.features.length - 1 > i,
                    })}
                    key={i}
                >
                    <span className="me-2">
                        <FaCheck className="opacity-75 text-success" />
                    </span>
                    <span className="opacity-75">{feature.label}</span>
                </li>
            ))}
        </Nav>

        {loading && (
            <button className="btn btn-primary w-100 mt-4" type="button" disabled="">
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                />
            </button>
        )}

        {!loading && (
            <Button className="btn btn-primary w-100 mt-4" onClick={() => initiatePayment(pricing.priceId, pricing.couponId)}>
                Choose plan
            </Button>
        )}

    </div>
);

function PaymentPlans({ userDetails, isLoggedIn }) {
    const [isActiveYearlyPricing, setIsActiveYearlyPricing] = useState(false);
    const switchActiveTimeline = (tab) => setIsActiveYearlyPricing(tab);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [pricingList, setPricingList] = useState({});
    const [newCustomer, setNewCustomer] = useState(false);

    const [checkoutClientSecret, setCheckoutClientSecret] = useState(null);

    const initiatePayment = async (priceId, couponId) => {
        setLoading(true);
        setErrors({});
        try {
            let stripeCustomerId;
            if (!userDetails.stripeObject?.customer_id) {
                const response = await axios.post(`${serverEndpoint}/payment/create-customer`, {
                    company_name: userDetails.businessName,
                    email: userDetails.username,
                    phone_number: userDetails.phone,
                }, { withCredentials: true });
                stripeCustomerId = response.data.customer.id;
            } else {
                stripeCustomerId = userDetails.stripeObject?.customer_id;
            }

            const requestBody = {
                customer_id: stripeCustomerId,
                price_id: priceId,
                referral_code: userDetails.referralCode
            };
            if (couponId) {
                requestBody.coupon_id = couponId;
            }
            axios.post(`${serverEndpoint}/payment/create-checkout-session`, requestBody, { withCredentials: true })
                .then((response) => {
                    setCheckoutClientSecret(response.data.clientSecret);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setErrors({ message: "Something went wrong, try selecting plan again" });
                });
        } catch (error) {
            console.log(error);
            setLoading(false);
            setErrors({ message: "Something went wrong, try selecting plan again" });
        }
    };

    const getPaymentPlans = async () => {
        try {
            const response = await axios.get(`${serverEndpoint}/payment/payment-plans`, { withCredentials: true });
            setPricingList(response.data.paymentPlans);
            setNewCustomer(response.data.newCustomer && !response.data.groupMember);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Unable to load payment plans' });
        } finally {
            setPageLoading(false);
        }
    };

    useEffect(() => {
        getPaymentPlans();
    }, []);

    if (pageLoading) {
        return <Spinner />;
    }

    if (errors.fatal) {
        return <ErrorComponent />;
    }

    // Present checkout form
    if (checkoutClientSecret) {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUSHABLE_KEY);
        const stripeOptions = {
            clientSecret: checkoutClientSecret
        };

        return (
            <div id="checkout">
                {errors.message && (
                    <AutoDismissibleAlert type="danger" message={errors.message} />
                )}
                <EmbeddedCheckoutProvider stripe={stripePromise} options={stripeOptions}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        );
    }

    return (
        <section className="container mt-5 card p-4">
            <Container>
                <Row className="justify-content-center mb-3">
                    <Col lg={6} xl={5} className="text-center">
                        {errors.message && (
                            <AutoDismissibleAlert type="danger" message={errors.message} />
                        )}
                        <h2 className="mb-2">
                            Flexible Plan for you
                        </h2>
                        <p className="text-muted">
                            To access your account, you need to subscribe to one of our available plans.
                        </p>
                        {newCustomer && (
                            <div className="alert alert-success" role="alert">
                                <strong>Free Trial!</strong> You are eligible for free 14 days trial. Choose and subscribe to any plan and you'll be only charged after your free trial ends
                            </div>
                        )}
                    </Col>
                </Row>
                {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN ? (
                    <>
                        <Collapse in={!isActiveYearlyPricing}>
                            <Row className="justify-content-center">
                                {pricingList.map((pricing, i) => (
                                    <Col md={6} lg={4} className="mt-4" key={i}>
                                        <PricingItem pricing={pricing} initiatePayment={initiatePayment} loading={loading} />
                                    </Col>
                                ))}
                            </Row>
                        </Collapse>
                    </>
                ) : (
                    <>
                        <div className="text-center mb-4">
                            Monthly
                            <div className="d-inline-flex align-items-center mx-2">
                                <Form.Check
                                    type="switch"
                                    label=""
                                    checked={isActiveYearlyPricing}
                                    onChange={(e) => switchActiveTimeline(e.target.checked)}
                                />
                            </div>
                            Annual
                        </div>
                        <Collapse in={!isActiveYearlyPricing}>
                            <Row className="justify-content-center">
                                {pricingList.monthlyPricings.map((pricing, i) => (
                                    <Col md={6} lg={4} className="mt-4" key={i}>
                                        <PricingItem pricing={pricing} initiatePayment={initiatePayment} loading={loading} />
                                    </Col>
                                ))}
                            </Row>
                        </Collapse>
                        <Collapse in={isActiveYearlyPricing}>
                            <Row className="justify-content-center">
                                {pricingList.yearlyPricings.map((pricing, i) => (
                                    <Col md={6} lg={4} className="mt-4" key={i}>
                                        <PricingItem pricing={pricing} initiatePayment={initiatePayment} loading={loading} />
                                    </Col>
                                ))}
                            </Row>
                        </Collapse>
                    </>
                )}

                {isLoggedIn && (
                    <div className="d-flex justify-content-end mt-5">
                        <p>
                            Switch Account? <Link to="/logout" className="btn-link">Logout</Link>
                        </p>
                    </div>
                )}
            </Container>
        </section>
    );
};

export default PaymentPlans;