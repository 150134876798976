import axios from "axios";
import { serverEndpoint } from "../../util/config";
import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { DEFAULT_PAGE_SIZE, USER_ROLES } from "../../util/constants";
import { getMonthYear } from "../../util/utilityFunctions";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import { FaFileExcel } from "react-icons/fa";
import { useSelector } from "react-redux";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import Select from 'react-select';
import { FaCaretRight, FaCaretDown } from "react-icons/fa";

function ViewCompletedTasksV2() {
    const userDetails = useSelector((state) => state.userDetails);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [tasksData, setTasksData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [startingFrom, setStartingFrom] = useState(null);
    const [noOfDocuments, setNoOfDocuments] = useState(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(-1);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [currentMonthYear, setCurrentMonthYear] = useState(convertToYearMonth(getMonthYear()));
    const [selectedItems, setSelectedItems] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isFilterExanded, setIsFilterExpanded] = useState(false);
    const [isExcelReportInProgress, setIsExcelReportInProgress] = useState(false);

    const [showExcelHeaderFieldModel, setShowExcelHeaderFieldModel] = useState(false);
    const [excelFormData, setExcelFormData] = useState({
        prepOwner: 'Seller',
        labelingOwner: 'Seller'
    });

    const toggleAccordion = () => {
        setIsFilterExpanded(!isFilterExanded);
    };

    const handleExcelHeaderFieldClose = () => setShowExcelHeaderFieldModel(false);
    const handleExcelHeaderModalShow = () => {
        setShowExcelHeaderFieldModel(true);
    }

    const handleExcelHeaderFieldChange = (e) => {
        const { name, value } = e.target;
        setExcelFormData({
            ...excelFormData,
            [name]: value,
        });
    };

    const [selectedPrepMasterAccount, setSelectedPrepMasterAccount] = useState(null);
    const [prepMasterAccountsLoading, setPrepMasterAccountsLoading] = useState(true);
    const [prepMasterAccounts, setPrepMasterAccounts] = useState([]);

    const handlePrepMasterAccountChange = async (option) => {
        setSelectedPrepMasterAccount(option);
    };

    const getPrepMasterAccounts = async () => {
        try {
            const response = await axios.post(`${serverEndpoint}/user/get-users`, {
                master_account_id: userDetails.masterAccountId
            }, { withCredentials: true });
            const accounts = response?.data?.users?.map((account) => ({
                value: account.email,
                label: account.email,
            }));
            setPrepMasterAccounts(accounts);
        } catch (error) {
            console.log(error);
            setErrors({ message: "Unable to fetch Prep Master Accounts for Filter" });
        } finally {
            setPrepMasterAccountsLoading(false);
        }
    };

    useEffect(() => {
        if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
            getPrepMasterAccounts();
        } else {
            setPrepMasterAccountsLoading(false);
        }
    }, []);

    const getTasks = async (reset = false) => {
        if (itemsLoading) return;

        try {
            setItemsLoading(true);

            const monthYear = convertToMonthYear(currentMonthYear);
            const params = {
                startingFrom: reset ? 0 : (startingFrom || 0),
                noOfDocuments: reset ? DEFAULT_PAGE_SIZE : (noOfDocuments || DEFAULT_PAGE_SIZE),
                month_year: monthYear, // Include the month_year parameter
                searchString: searchQuery
            };

            let response;
            if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
                if (selectedPrepMasterAccount !== null) {
                    params.emailId = selectedPrepMasterAccount.value;
                    response = await axios.get(`${serverEndpoint}/tasks/get-tasks/COMPLETED`, {
                        params: params,
                        withCredentials: true,
                    });
                } else {
                    response = await axios.get(`${serverEndpoint}/tasks/prep-center/${userDetails.masterAccountId}/get-tasks/COMPLETED`, {
                        params: params,
                        withCredentials: true,
                    });
                }
            } else {
                response = await axios.get(`${serverEndpoint}/tasks/get-tasks/COMPLETED`, {
                    params: params,
                    withCredentials: true,
                });
            }
            
            const newDocuments = response?.data?.documents || [];
            const pagination = response?.data?.pagination || {};
            setTasksData(!reset ? (prev) => [...prev, ...newDocuments] : newDocuments);
            setDisplayData(!reset ? (prev) => [...prev, ...newDocuments] : newDocuments);
            setStartingFrom(pagination.startingFrom + pagination.noOfDocuments);
            setNoOfDocuments(pagination.noOfDocuments);
            setTotalRecords(pagination.total);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
        } finally {
            setLoading(false);
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getTasks();
    }, [page]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getTasks(true);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, selectedPrepMasterAccount, currentMonthYear]);

    const handleMonthChange = (e) => {
        const { value } = e.target;
        setCurrentMonthYear(value);
    };

    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        overflowY: 'hidden',
                    },
                    columnHeaders: {
                        fontWeight: 'bold', // Apply bold to the entire header row container
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold', // Ensure individual header text is bold
                        },
                    },
                },
            },
        },
    });
    const handleCheckboxChange = (row, isChecked) => {
        const updatedRow = displayData.find((item) => item?.sk === row?.sk);
        if (isChecked) {
            const newItem = {
                sk: updatedRow.sk,
                sku: updatedRow.sku ? updatedRow.sku : 'Not Available',
                quantity: updatedRow?.quantity_to_prepare?.toString(),
            };

            setSelectedItems((prevSelectedItems) => {
                const updatedSelectedItems = [...prevSelectedItems, newItem];
                setIsButtonDisabled(updatedSelectedItems.length === 0);
                return updatedSelectedItems;
            });
        } else {
            setSelectedItems((prevSelectedItems) => {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (item) => item.sk !== updatedRow.sk
                );
                setIsButtonDisabled(updatedSelectedItems.length === 0);
                return updatedSelectedItems;
            });
        }
    };

    const handleGenerateExcel = async (e) => {
        e.preventDefault();
        setMessage(null);
        handleExcelHeaderFieldClose();
        if (selectedItems.length === 0) {
            setErrors({ message: 'Please select at least one item to proceed!' });
            return;
        }

        const payload = {
            prep_owner: excelFormData.prepOwner, // Hardcoded for now
            labeling_owner: excelFormData.labelingOwner, // Hardcoded for now
            excelData: selectedItems.map((item) => ({
                sku: item.sku,
                quantity: item.quantity,
            })),
        };

        try {
            setIsExcelReportInProgress(true);
            const response = await axios.post(`${serverEndpoint}/async/generate-excelsheet`, payload, { withCredentials: true });
            if (response.status === 200) {
                setMessage(
                    <>
                        Your export request has been submitted successfully!<br />
                        Request ID: <strong>{response.data.data.uuid}. </strong>
                        You can track the progress of your export{" "}
                        <Link to={`/reports`}>here</Link>.
                    </>
                );
            } else {
                setErrors({ message: 'Failed to generate excel report, please try again' });
            }
        } catch (error) {
            console.error('Error generating Excel sheet:', error);
            setErrors({ message: 'Failed to generate excel report, please try again' });
        } finally {
            setIsExcelReportInProgress(false);
        }
    };



    const columns = [
        { field: 'sk', headerName: 'Item ID', width: 300 },
        {
            field: 'select',
            headerName: 'Select',
            width: 80,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const isChecked = selectedItems.some(
                    (item) => item.sk === params.row.sk
                );

                return (
                    <div className="text-center mt-3">
                        <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={(event) => handleCheckboxChange(params.row, event.target.checked)}
                            className="form-check-input text-center"

                        />
                    </div>
                );
            },
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 350,
            editable: false,
            renderCell: (params) => {
                const splitedSk = params.row.item_id.split('#');
                let url;
                if (splitedSk[0] === 'B') {
                    url = `/bundle/${splitedSk[1]}`;
                } else if (splitedSk[0] === 'L') {
                    url = `/listing/${splitedSk[1]}`;
                } else if (splitedSk[0] === 'C') {
                    url = `/component/${splitedSk[1]}`;
                } else {
                    url = '#';
                }

                return (
                    <>
                        <Link className="mx-1" to={url}>
                            <img src={params.row.image_url ? params.row.image_url : '/images/default-image.jpg'}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                    e.target.src = "/images/default-image.jpg";
                                }}
                                alt={params.row.title} className="rounded-1" style={{
                                    maxHeight: '30px'
                                }} />
                        </Link>
                        <Link className="mx-1 text-body text-decoration-none hover:text-primary" to={url}>{params.row.title}</Link>
                    </>
                );
            }
        },
        {
            field: 'creation_date',
            headerName: 'Creation Date',
            width: 130,
            editable: false,
            renderCell: (params) => (
                <div>
                    {format(new Date(params.row.creation_date), 'MMM d, yyyy')}
                </div>
            )
        },
        {
            field: 'completion_date',
            headerName: 'Completion Date',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <div>
                    {format(new Date(params.row.completion_date), 'MMM d, yyyy')}
                </div>
            )
        },
        {
            field: 'quantity_to_prepare',
            headerName: 'Qty Prepared',
            width: 130,
            editable: false
        },
        {
            field: 'marked_by',
            headerName: 'Completed By',
            width: 300,
            editable: false
        }
    ];

    // This is needed only when prep center admin is viewing the tasks!
    const prepMasterAccountEmail = {
        field: 'pk',
        headerName: 'Prep Master',
        width: 300,
        renderCell: (params) => (
            <div>
                {params.row.pk.split('#')[0]}
            </div>
        ),
    };

    if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
        columns.splice(2, 0, prepMasterAccountEmail);
    }

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <section>
            <div className="container p-4">
                <div className="row align-items-center">
                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}
                    {message && (
                        <AutoDismissibleAlert type="success" message={message} />
                    )}
                    {/* Left Section: Title and Subtitle */}
                    <div className="col">
                        <h2 className="mb-0">Completed Tasks</h2>
                        <p className="text-secondary mb-0">View completed tasks by month</p>
                    </div>

                    {/* Right Section: Button */}
                    <div className="col-auto">
                        {isExcelReportInProgress && (
                            <button className="btn btn-success text-white ms-auto btn-sm d-block d-md-inline" type="button" disabled="">
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </button>
                        )}

                        {!isExcelReportInProgress && (
                            <button className="btn btn-success text-white ms-auto btn-sm d-block d-md-inline" type="submit" onClick={handleExcelHeaderModalShow} disabled={isButtonDisabled}>
                                <FaFileExcel className="mb-1" /> Export
                            </button>
                        )}
                    </div>
                </div>

                <div className="accordion mt-3" id="accordionExample">
                    {/* Accordion Item 1 */}
                    <div className="accordion-item">
                        <h6 className="accordion-header py-3" id="headingOne">
                            <div className="d-flex align-items-center w-100">
                                <div className="form-check d-flex align-items-center">
                                    <Link
                                        className="text-dark btn-link cursor-pointer text-decoration-none"
                                        data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                        aria-controls="collapseOne" onClick={toggleAccordion}>
                                        {isFilterExanded ? (
                                            <FaCaretDown className="text-dark mb-1" size={18} />
                                        ) : (
                                            <FaCaretRight className="text-dark mb-1" size={18} />
                                        )}
                                        Search & Filter
                                    </Link>
                                </div>
                            </div>
                        </h6>

                        <div
                            id="collapseOne"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                        >
                            <hr className='m-0' />
                            <div className="accordion-body">
                                <div className="row">
                                    <div className="col">
                                        <label for="exampleFormControlInput1" className="form-label">Search</label>
                                        <input type="text" placeholder="Search by title or Description" value={searchQuery}
                                            className="form-control" id="exampleFormControlInput1" onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                        <div className="col-md-6 mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">Filter By: Prep Master Accounts</label>
                                            <Select
                                                options={prepMasterAccounts}
                                                isClearable={true}
                                                placeholder="Select"
                                                isLoading={itemsLoading || prepMasterAccountsLoading}
                                                isDisabled={itemsLoading || prepMasterAccountsLoading}
                                                value={selectedPrepMasterAccount}
                                                onChange={handlePrepMasterAccountChange}
                                            />
                                        </div>
                                    )}

                                    <div className={userDetails.role === USER_ROLES.PREP_CENTER_ADMIN ? 'col-md-6' : 'col-md-12'}>
                                        <label for="exampleFormControlInput1" className="form-label">Filter By: Month</label>
                                        <input
                                            type="month"
                                            className="form-control"
                                            id="monthYear"
                                            name="monthYear"
                                            defaultValue={currentMonthYear}
                                            onChange={handleMonthChange}
                                            max={convertToYearMonth(getMonthYear())}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }} className="mt-3 p-3 rounded border bg-white">
                    <ThemeProvider theme={theme}>
                        <Box>
                            <DataGrid
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            sk: false
                                        }
                                    },
                                    pagination: {
                                        paginationModel: { page: page, pageSize: noOfDocuments || DEFAULT_PAGE_SIZE }, // Set initial state explicitly
                                    },
                                }}
                                rows={displayData.slice(page * pageSize, (page + 1) * pageSize)}
                                getRowId={(row) => row.sk}
                                rowCount={totalRecords}
                                columns={columns}
                                disableRowSelectionOnClick
                                showCellVerticalBorder={true}
                                showColumnVerticalBorder={true}
                                pagination
                                paginationMode="server"
                                pageSize={pageSize}
                                page={page} // Controlled page
                                rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
                                onPaginationModelChange={({ page }) => {
                                    handlePageChange(page);
                                }}
                                loading={loading || itemsLoading}
                                sx={{
                                    minHeight: displayData.length === 0 ? 400 : 'auto',
                                    '& .MuiDataGrid-overlay': {
                                        backgroundColor: '#ffffff',
                                    },
                                }}
                                localeText={{
                                    noRowsLabel: "No data available to display",
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                </div>
            </div>
            <Modal show={showExcelHeaderFieldModel} onHide={handleExcelHeaderFieldClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Prep Owner and Labeling Owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleGenerateExcel}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="prepOwner">
                                Prep Owner
                            </label>
                            <select className="form-control form-control-sm" id="prepOwner" name="prepOwner"
                                value={excelFormData.prepOwner} onChange={handleExcelHeaderFieldChange}
                            >
                                <option value='Seller'>Seller</option>
                                <option value='Amazon'>Amazon</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="labelingOwner">
                                Labeling Owner
                            </label>
                            <select className="form-control form-control-sm" id="labelingOwner" name="labelingOwner"
                                value={excelFormData.labelingOwner} onChange={handleExcelHeaderFieldChange}
                            >
                                <option value='Seller'>Seller</option>
                                <option value='Amazon'>Amazon</option>
                            </select>
                        </div>

                        <div className="align-items-center d-flex">
                            <button className="btn btn-primary btn-sm ms-auto" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </section>
    );
}

const convertToYearMonth = (dateStr) => {
    const month = dateStr.split('-')[0];
    const year = dateStr.split('-')[1];
    return `${year}-${month}`;
}

const convertToMonthYear = (dateStr) => {
    const year = dateStr.split('-')[0];
    const month = dateStr.split('-')[1];
    return `${month}-${year}`;
}

export default ViewCompletedTasksV2;