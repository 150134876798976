import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

function AutoDismissibleAlert({ type = "warning", message = "This is an alert!", dismissTime = 10000 }) {
    const [show, setShow] = useState(true);
    const [progress, setProgress] = useState(100);

    const progressBarColors = {
        primary: "#0d6efd",
        secondary: "#6c757d",
        success: "#198754",
        danger: "#dc3545",
        warning: "#ffc107",
        info: "#0dcaf0",
        light: "#f8f9fa",
        dark: "#212529",
    };

    const progressBarColor = progressBarColors[type] || "#0d6efd";

    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, dismissTime);

        const interval = setInterval(() => {
            setProgress((prev) => Math.max(0, prev - (100 / (dismissTime / 100))));
        }, 100);

        return () => {
            clearTimeout(timer);
            clearInterval(interval);
        };
    }, [dismissTime]);

    if (!show) return null;

    return (
        <div
            className={`alert alert-${type} alert-dismissible fade show`}
            role="alert"
            style={{
                position: "relative",
                overflow: "hidden",
                transition: "opacity 0.5s ease-in-out",
            }}
        >
            <strong>{message}</strong>
            <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShow(false)}
            ></button>

            <div
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    height: "5px",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        backgroundColor: progressBarColor,
                        width: `${progress}%`,
                        transition: "width 0.1s linear",
                    }}
                ></div>
            </div>
        </div>
    );
};

export default AutoDismissibleAlert;
