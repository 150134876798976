import { useState } from "react";
import axios from 'axios';
import { serverEndpoint } from "../../../util/config";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import AutoDismissibleAlert from "../../../components/AutoDismissibleAlert";

function AddPrepMasterUser({ userDetails }) {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
    });

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        const phoneRegex = /^\+[1-9]{1}[0-9]{7,14}$/;
        if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Please provide a valid Phone';
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please provide a valid Email';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, [name]: value };
            return updatedFormData;
        });
    };

    const handleSubmit = async (e) => {
        setMessage(null);
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/user/prep-center/add-user`, {
                username: formData.email,
                phone: formData.phone,
                master_account_id: userDetails.masterAccountId,
                business_name: userDetails.businessName
            }, { withCredentials: true })
                .then(response => {
                    setMessage('User created! A temporary password has been sent to their email.');
                    setFormData({
                        email: '',
                        phone: '',
                    });
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    if (error.response?.data?.error) {
                        setErrors({ message: error.response.data.error });
                    } else if (error.response?.data?.message) {
                        setErrors({ message: error.response.data.message });
                    } else {
                        setErrors({ message: "Something went wrong, please try again!" });
                    }
                    setLoading(false);
                });
        }
    };

    return (
        <div className="container">
            <CRow>
                <CCol xs={12}>
                    
                        <div className="">
                            <h2>Add Account</h2>
                            <p className="text-secondary mb-2">Add a Prep Master Account</p>
                        </div>
                        {errors.message && (
                            <AutoDismissibleAlert type="danger" message={errors.message} />
                        )}

                        {message && (
                            <AutoDismissibleAlert type="success" message={message} />
                        )}

                        <form onSubmit={handleSubmit} className="border rounded bg-white p-4">
                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="email">
                                    Email
                                </label>
                                <input id="email" type="text" className={errors.email ? 'form-control is-invalid' : 'form-control'}
                                    name="email" placeholder="name@host.com" onChange={handleChange}
                                    value={formData.email} autoFocus
                                />
                                <div className="invalid-feedback">Please enter a valid email.</div>
                            </div>
                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="phone">
                                    Phone
                                </label>
                                <input id="phone" type="text" name="phone" placeholder="+440123456789"
                                    className={errors.phone ? 'form-control is-invalid' : 'form-control'}
                                    onChange={handleChange} value={formData.phone}
                                />
                                <div className="invalid-feedback">Phone number must start with '+' followed by the country code and only contain digits. Remove any other characters (e.g., spaces, dashes). Format example: +14325551212.</div>
                            </div>

                            <div className="align-items-center d-flex">
                                {loading && (
                                    <button className="btn btn-primary ms-auto" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                )}

                                {!loading && (
                                    <button className="btn btn-primary ms-auto" type="submit">
                                        Add
                                    </button>
                                )}
                            </div>
                        </form>
                </CCol>
            </CRow >
        </div>
    );
}

export default AddPrepMasterUser;
