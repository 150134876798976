import React, { useEffect, useState } from "react";
import Spinner from "../../../components/Spinner"
import { Button, Col, Collapse, Container, Nav, Row, Form, Modal } from "react-bootstrap";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa6";
import { serverEndpoint } from "../../../util/config";
import axios from "axios";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AutoDismissibleAlert from "../../../components/AutoDismissibleAlert";

const PricingItem = ({ pricing, openConfirmationModal }) => (
    <div className="active rounded-4 shadow-lg p-3 p-lg-5">
        <h3>{pricing.planTitle}</h3>
        <div className="mb-3">
            {(pricing.discountPrice || pricing.discountPrice === 0) ? (
                <>
                    <span className="fs-5 text-muted fw-bold text-decoration-line-through">
                        £{pricing.price}
                    </span>
                    <span className="ms-2 fs-3 fw-bold">£{pricing.discountPrice}</span>
                    <span className="ms-2 opacity-50">{pricing.timeline}</span>
                </>
            ) : (
                <>
                    <span className="fs-3 fw-bold">£{pricing.price}</span>
                    <span className="ms-2 opacity-50">{pricing.timeline}</span>
                </>
            )}
        </div>
        <p className="mb-3 opacity-50">{pricing.description}</p>
        <Nav className="flex-column">
            {pricing.features.map((feature, i) => (
                <li className={classNames({ "mb-3": pricing.features.length - 1 > i })} key={i}>
                    <span className="me-2">
                        <FaCheck className="opacity-75 text-success" />
                    </span>
                    <span className="opacity-75">{feature.label}</span>
                </li>
            ))}
        </Nav>
        <Button className="btn btn-primary w-100 mt-4" onClick={() => openConfirmationModal(pricing)}>
            Choose plan
        </Button>
    </div>
);

const getPricingList = async (userDetails) => {
    try {
        const response = await axios.post(`${serverEndpoint}/payment/check-subscription`, {
            stripe_customer_id: userDetails.stripeObject?.customer_id,
            requirePlans: true,
            referral_code: userDetails.referralCode
        }, { withCredentials: true });
        return response.data;
    } catch (error) {
        console.error(error);
    }
    return null;
};

function ChangeSubscription({ userDetails }) {
    const [isActiveYearlyPricing, setIsActiveYearlyPricing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [checkoutClientSecret, setCheckoutClientSecret] = useState(null);
    const [subscriptionData, setSubscriptionData] = useState(null);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);

    useEffect(() => {
        const fetchPricingList = async () => {
            setLoading(true);
            const data = await getPricingList(userDetails);
            if (data) {
                setSubscriptionData(data);
            } else {
                setError("Failed to fetch pricing plans. Please try again later.");
            }
            setLoading(false);
        };
        fetchPricingList();
    }, [userDetails.username]);

    const openConfirmationModal = (plan) => {
        setSelectedPlan(plan);
        setShowConfirmationModal(true);
    };

    const initiatePayment = async () => {
        setLoading(true);
        setError(null);
        setShowConfirmationModal(false); // Close modal when payment is initiated
        const subscriptionID = subscriptionData.subscription.subscriptionId;
        const customerID = subscriptionData.customer_id;
        try {
            await axios.post(`${serverEndpoint}/payment/cancel-subscription`,
                { email: userDetails.username, customerID: customerID, subscriptionId: subscriptionID },
                { withCredentials: true });

            const checkoutResponse = await axios.post(`${serverEndpoint}/payment/create-checkout-session`, {
                customer_id: customerID,
                plan_type: selectedPlan.planId,
                coupon_id: selectedPlan.couponId,
                referral_code: userDetails.referralCode
            }, { withCredentials: true });

            setCheckoutClientSecret(checkoutResponse.data.clientSecret);
        } catch (error) {
            console.error(error);
            setError("Something went wrong, try selecting the plan again.");
        }
        setLoading(false);
    };

    if (checkoutClientSecret) {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUSHABLE_KEY);
        const stripeOptions = {
            clientSecret: checkoutClientSecret
        };

        return (
            <div id="checkout">
                {error && <AutoDismissibleAlert type="danger" message={error} />}
                <EmbeddedCheckoutProvider stripe={stripePromise} options={stripeOptions}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        );
    }

    if (!subscriptionData) {
        return <Spinner/>;
    }

    return (
        <section className="container mt-5 card p-4">
            <Container>
                <Row className="justify-content-center mb-3">
                    <Col lg={6} xl={5} className="text-center">
                        {error && <AutoDismissibleAlert type="danger" message={error} />}
                        <h2 className="mb-2">Flexible Plan for you</h2>
                        <p className="text-muted">Choose a suitable plan for you.</p>
                    </Col>
                </Row>
                <div className="text-center mb-4">
                    Monthly
                    <div className="d-inline-flex align-items-center mx-2">
                        <Form.Check
                            type="switch"
                            checked={isActiveYearlyPricing}
                            onChange={(e) => setIsActiveYearlyPricing(e.target.checked)}
                        />
                    </div>
                    Annual
                </div>
                <Collapse in={!isActiveYearlyPricing}>
                    <Row className="justify-content-center">
                        {subscriptionData.pricingList.monthlyPricings.map((pricing, i) => (
                            <Col md={6} lg={4} className="mt-4" key={i}>
                                <PricingItem pricing={pricing} openConfirmationModal={() => openConfirmationModal(pricing)} />
                            </Col>
                        ))}
                    </Row>
                </Collapse>
                <Collapse in={isActiveYearlyPricing}>
                    <Row className="justify-content-center">
                        {subscriptionData.pricingList.yearlyPricings.map((pricing, i) => (
                            <Col md={6} lg={4} className="mt-4" key={i}>
                                <PricingItem pricing={pricing} openConfirmationModal={() => openConfirmationModal(pricing)} />
                            </Col>
                        ))}
                    </Row>
                </Collapse>

                <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Plan Change Notification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        You are about to change your subscription plan. 
                        Please note that if you downgrade to a Standard plan, you will lose access to all Advanced account features, 
                        and all your child accounts will be disabled.
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={initiatePayment} disabled={loading}>
                            {loading ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            ) : "Confirm and Pay"}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </section>
    );
}

export default ChangeSubscription;
