import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint, CURRENCY_TO_SYMBOL_MAP } from "../../util/config";
import { Card, Col, Row, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsTelephoneInbound } from "react-icons/bs";
import { LuListTodo } from "react-icons/lu";
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import { DEFAULT_PAGE_SIZE, USER_ROLES } from "../../util/constants";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import Select from 'react-select';
import { FaCaretRight, FaCaretDown } from "react-icons/fa";

function ViewBundles() {
    const userDetails = useSelector((state) => state.userDetails);
    const currencySymbol = CURRENCY_TO_SYMBOL_MAP[userDetails.accountCurrency] || '£';
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsLoading, setItemsLoading] = useState(false);
    const [moreDataAvailable, setMoreDataAvailable] = useState(false);
    const [startingFrom, setStartingFrom] = useState(null);
    const [noOfDocuments, setNoOfDocuments] = useState(null);
    const [isFilterExanded, setIsFilterExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsFilterExpanded(!isFilterExanded);
    };

    const getBundles = async (reset = false) => {
        if (itemsLoading) return;

        try {
            setItemsLoading(true);
            const params = {
                startingFrom: reset ? 0 : startingFrom,
                noOfDocuments: noOfDocuments || DEFAULT_PAGE_SIZE,
            };

            let response;
            if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
                if (selectedPrepMasterAccount !== null) {
                    params.emailId = selectedPrepMasterAccount.value;
                    response = await axios.get(`${serverEndpoint}/bundles/get-bundles/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                } else {
                    response = await axios.get(`${serverEndpoint}/bundles/prep-center/${userDetails.masterAccountId}/get-bundles/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                }
            } else {
                response = await axios.get(`${serverEndpoint}/bundles/get-bundles/${searchQuery}`, {
                    params: params,
                    withCredentials: true,
                });
            }
            const newDocuments = response?.data?.documents || [];
            const pagination = response?.data?.pagination || {};
            if (pagination.currentPage === pagination.totalPages || pagination.currentPage > pagination.totalPages) {
                setMoreDataAvailable(false);
            } else {
                setMoreDataAvailable(true);
            }
            setDisplayData((prev) => reset ? newDocuments : [...prev, ...newDocuments]);
            setStartingFrom(pagination.startingFrom + pagination.noOfDocuments);
            setNoOfDocuments(pagination.noOfDocuments);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
        } finally {
            setLoading(false);
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getBundles();
    }, []);


    const [selectedPrepMasterAccount, setSelectedPrepMasterAccount] = useState(null);
    const [prepMasterAccountsLoading, setPrepMasterAccountsLoading] = useState(true);
    const [prepMasterAccounts, setPrepMasterAccounts] = useState([]);

    const handlePrepMasterAccountChange = async (option) => {
        setSelectedPrepMasterAccount(option);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getBundles(true);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, selectedPrepMasterAccount]);

    const getPrepMasterAccounts = async () => {
        try {
            const response = await axios.post(`${serverEndpoint}/user/get-users`, {
                master_account_id: userDetails.masterAccountId
            }, { withCredentials: true });
            const accounts = response?.data?.users?.map((account) => ({
                value: account.email,
                label: account.email,
            }));
            setPrepMasterAccounts(accounts);
        } catch (error) {
            console.log(error);
            setErrors({ message: "Unable to fetch Prep Master Accounts for Filter" });
        } finally {
            setPrepMasterAccountsLoading(false);
        }
    };

    useEffect(() => {
        if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
            getPrepMasterAccounts();
        } else {
            setPrepMasterAccountsLoading(false);
        }
    }, []);

    const [markInboundLoading, setMarkInboundLoading] = useState(false);
    const [showMarkInboundModal, setShowMarkInboundModal] = useState(false);
    const [markInboundFormData, setMarkInboundFormData] = useState({
        listings: {},
        components: {}
    });

    const handleClose = () => setShowMarkInboundModal(false);
    const handleShow = (inboundItem) => {
        if (!hasPermission(userDetails, "Inbound", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setErrors({});

        const initializedListings = {};
        const initializedComponents = {};

        Object.entries(inboundItem.listings).forEach(([id, listing]) => {
            initializedListings[id] = {
                ...listing,
                inboundQty: 0,
                trackingNumber: ''
            };
        });

        Object.entries(inboundItem.components).forEach(([id, component]) => {
            initializedComponents[id] = {
                ...component,
                inboundQty: 0,
                trackingNumber: ''
            };
        });

        setMarkInboundFormData({
            listings: initializedListings,
            components: initializedComponents,
            ...(userDetails.prepCenterId && { prep_center_id: userDetails.prepCenterId })
        });
        setShowMarkInboundModal(true);
    }

    const handleInputChange = (id, name, value, type, data) => {
        setMarkInboundFormData(prevState => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                [id]: {
                    ...data, // Include the full listing/component data
                    [name]: value // Store the user input value
                }
            }
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        for (let [id, listing] of Object.entries(markInboundFormData.listings)) {
            if (!listing.inboundQty || listing.inboundQty <= 0) {
                newErrors[id] = 'Please enter a value greater than 0';
                isValid = false;
            }
        }

        for (let [id, component] of Object.entries(markInboundFormData.components)) {
            if (!component.inboundQty || component.inboundQty <= 0) {
                newErrors[id] = 'Please enter a value greater than 0';
                isValid = false;
            }
        }
        setErrors(newErrors);
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setMarkInboundLoading(true);
            if (userDetails.prepCenterId) {
                markInboundFormData.prep_center_id = userDetails.prepCenterId;
            }
            axios.post(`${serverEndpoint}/inbound/create`, markInboundFormData, { withCredentials: true })
                .then(response => {
                    setMarkInboundLoading(false);
                    handleClose();
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Unable to add Bundle, please try again!" });
                });
        }
    };


    const [addToDoLoading, setAddTodoLoading] = useState(false);
    const [showAddToDoModal, setShowAddToDoModal] = useState(false);
    const [addToDoFormData, setAddToDoFormData] = useState({
        quantityToPrepare: 0
    });

    const handleAddToDoClose = () => setShowAddToDoModal(false);
    const handleAddToDoShow = (todoItem) => {
        if (!hasPermission(userDetails, "Task Management", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }
        setErrors({});
        setAddToDoFormData({
            quantityToPrepare: 0,
            itemToPrepare: todoItem
        });
        setShowAddToDoModal(true);
    }

    const handleAddTodoFormChange = (e) => {
        const { name, value } = e.target;
        setAddToDoFormData({
            ...addToDoFormData,
            [name]: value,
        });
    };

    const handleAddTodoSubmit = async (e) => {
        e.preventDefault();
        if (addToDoFormData.quantityToPrepare <= 0) {
            setErrors({
                quantityToPrepare: 'Please enter a valid Quantity'
            });
            return;
        }

        setAddTodoLoading(true);
        for (const key in addToDoFormData.itemToPrepare.listings) {
            delete addToDoFormData.itemToPrepare.listings[key].image_url;
        }
        for (const key in addToDoFormData.itemToPrepare.components) {
            delete addToDoFormData.itemToPrepare.components[key].image_url;
        }
        axios.post(`${serverEndpoint}/tasks/create-task`, {
            title: addToDoFormData.itemToPrepare.title,
            quantity_to_prepare: addToDoFormData.quantityToPrepare,
            listings: addToDoFormData.itemToPrepare.listings ? addToDoFormData.itemToPrepare.listings : {},
            components: addToDoFormData.itemToPrepare.components ? addToDoFormData.itemToPrepare.components : {},
            bundles: {},
            image_key: addToDoFormData.itemToPrepare.image_key,
            item_id: addToDoFormData.itemToPrepare.sk,
            prep_center_id: userDetails.prepCenterId
        }, { withCredentials: true })
            .then(response => {
                setAddTodoLoading(false);
                handleAddToDoClose();
            }).catch(error => {
                console.log(error);
                handleAddToDoClose();
                setErrors({ message: "Unable to create ToDo task!" });
            });
    };


    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container p-4">
                    <div className="ms-1">
                        {errors.message && (
                            <AutoDismissibleAlert type="danger" message={errors.message} />
                        )}
                        <h2>Bundles</h2>
                        <p className="text-secondary mb-2">View & Manage all your Bundles</p>
                    </div>

                    <div className="row m-1 accordion mt-3" id="accordionExample">
                        {/* Accordion Item 1 */}
                        <div className="accordion-item">
                            <h6 className="accordion-header py-3" id="headingOne">
                                <div className="d-flex align-items-center w-100">
                                    <div className="form-check d-flex align-items-center">
                                        <Link
                                            className="text-dark btn-link cursor-pointer text-decoration-none"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                            aria-controls="collapseOne" onClick={toggleAccordion}>
                                            {isFilterExanded ? (
                                                <FaCaretDown className="text-dark mb-1" size={18} />
                                            ) : (
                                                <FaCaretRight className="text-dark mb-1" size={18} />
                                            )}
                                            Search & Filter
                                        </Link>
                                    </div>
                                </div>
                            </h6>

                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <hr className='m-0' />
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Search</label>
                                            <input type="text" placeholder="Search by title or Description" value={searchQuery}
                                                className="form-control" id="exampleFormControlInput1" onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                        <div className="row mt-4">
                                            <div className="col">
                                                <label htmlFor="exampleFormControlInput1" className="form-label">Filter By: Prep Master Accounts</label>
                                                <Select
                                                    options={prepMasterAccounts}
                                                    isClearable={true}
                                                    placeholder="Select"
                                                    isLoading={itemsLoading || prepMasterAccountsLoading}
                                                    isDisabled={itemsLoading || prepMasterAccountsLoading}
                                                    value={selectedPrepMasterAccount}
                                                    onChange={handlePrepMasterAccountChange}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Row className="mt-3 text-center p-2 bg-white border rounded m-1">
                        {displayData?.length === 0 && !itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <p>No results found</p>
                            </div>
                        )}

                        {displayData.map((bundle) => (
                            <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={bundle.sk}>
                                <Card className="position-relative h-100 d-flex flex-column">
                                    <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                        <Link to={`/bundle/${bundle.sk.split('B#')[1]}${userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && bundle.pk ? `/${encodeURIComponent(bundle.pk.split('#')[0])}` : ''}`} >
                                            <img src={bundle.image_url ? bundle.image_url : '/images/default-image.jpg'}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                    e.target.src = "/images/default-image.jpg";
                                                }}
                                                alt={bundle.title} className="rounded" style={{
                                                    maxHeight: '210px',
                                                    width: '100%',
                                                    objectFit: 'cover'
                                                }} />
                                        </Link>
                                    </Card.Body>
                                    <Card.Body className="flex-grow-1 pt-0">
                                        <Link to={`/bundle/${bundle.sk.split('B#')[1]}${userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && bundle.pk ? `/${encodeURIComponent(bundle.pk.split('#')[0])}` : ''}`}
                                            className="text-body text-decoration-none hover:text-primary"
                                        >
                                            <h5 className="w-100" title={bundle.title}>
                                                {bundle?.title?.length > 20 ? `${bundle.title.substring(0, 20)}...` : bundle.title}
                                            </h5>
                                        </Link>
                                        <h5 className="text-primary"><strong>{currencySymbol}{bundle.sale_price}</strong></h5>
                                        {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                            <p className="text-muted mb-0"><strong>{bundle.pk.split('#')[0]}</strong></p>
                                        )}
                                    </Card.Body>
                                    {(hasPermission(userDetails, "Inbound", "Add") || hasPermission(userDetails, "Task Management", "Add")) && (
                                        <div className="d-flex justify-content-center mb-4">
                                            {hasPermission(userDetails, "Inbound", "Add") && (
                                                <div className="mx-2">
                                                    <button className="btn btn-sm btn-outline-success" onClick={() => handleShow(bundle)}>
                                                        <BsTelephoneInbound size={15} /> Mark Inbound
                                                    </button>
                                                </div>
                                            )}
                                            {hasPermission(userDetails, "Task Management", "Add") && (
                                                <div className="mx-2">
                                                    <button className="btn btn-sm btn-outline-info" onClick={() => handleAddToDoShow(bundle)}>
                                                        <LuListTodo size={18} className="mb-0" /> Add to ToDo
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {moreDataAvailable && (
                        <div className="row justify-content-center mt-4">
                            <div className="col-auto">

                                {itemsLoading ? (
                                    <button className="btn btn-primary" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                ) : (
                                    <button onClick={() => getBundles()} className="btn btn-primary">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </div>
                    )}

                </div>
            </section>

            <Modal show={showMarkInboundModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Inbound Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}
                    <form onSubmit={handleSubmit}>
                        {Object.entries(markInboundFormData.listings).map(([id, listing]) => (
                            <div key={id} className="mb-3">
                                <label className="mb-2 text-muted">
                                    {listing.title}
                                </label>
                                <input type="number" name="inboundQty" id="inboundQty" className={errors[id] ? 'form-control is-invalid' : 'form-control'}
                                    value={markInboundFormData.listings[id]?.inboundQty || ''}
                                    onChange={(e) => handleInputChange(id, e.target.name, e.target.value, 'listings', listing)}
                                    placeholder="Quantity"
                                />
                                <div className="invalid-feedback">Please provide a valid Quantity</div>
                                <input type="text" name="trackingNumber" id="trackingNumber" className='form-control mt-2'
                                    value={markInboundFormData.listings[id]?.trackingNumber || ''}
                                    onChange={(e) => handleInputChange(id, e.target.name, e.target.value, 'listings', listing)}
                                    placeholder="Tracking Number (Optional)"
                                />
                            </div>
                        ))}

                        {Object.entries(markInboundFormData.components).map(([id, component]) => (
                            <div key={id} className="mb-3">
                                <label className="mb-2 text-muted">
                                    {component.title}
                                </label>
                                <input type="number" name="inboundQty" id="inboundQty" className={errors[id] ? 'form-control is-invalid' : 'form-control'}
                                    value={markInboundFormData.components[id]?.inboundQty || ''}
                                    onChange={(e) => handleInputChange(id, e.target.name, e.target.value, 'components', component)}
                                    placeholder="Quantity"
                                />
                                <div className="invalid-feedback">Please provide a valid Quantity</div>
                                <input type="text" name="trackingNumber" id="trackingNumber" className='form-control mt-2'
                                    value={markInboundFormData.components[id]?.trackingNumber || ''}
                                    onChange={(e) => handleInputChange(id, e.target.name, e.target.value, 'components', component)}
                                    placeholder="Tracking Number (Optional)"
                                />
                            </div>
                        ))}

                        <div className="align-items-center d-flex">
                            {markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddToDoModal} onHide={handleAddToDoClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create ToDo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddTodoSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="quantityToPrepare">
                                Quantity to Prepare
                            </label>
                            <input id="quantityToPrepare" type="number" className={errors?.quantityToPrepare ? 'form-control is-invalid' : 'form-control'}
                                name="quantityToPrepare" value={addToDoFormData.quantityToPrepare} autoFocus onChange={handleAddTodoFormChange}
                            />
                            <div className="invalid-feedback">Please provide a valid Quantity</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {addToDoLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!addToDoLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Create
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewBundles;