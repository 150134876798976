import { useEffect, useState } from "react";
import { FaAmazon } from "react-icons/fa";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import Spinner from "../../components/Spinner";
import ErrorComponent from "../../components/Error";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";

function AmazonSellerAccount() {
    const [isConnected, setIsConnected] = useState(false);
    const [partialLoad, setPartialLoad] = useState(false);
    const [syncInProgress, setSyncInProgress] = useState(true);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [marketplace, setMarketplace] = useState(null);
    const [message, setMessage] = useState(null);
    const [notificationEnabled, setNotificationEnabled] = useState(false);

    const handleMarketplaceChange = (event) => {
        setMarketplace(event.target.value);
    };

    const [syncData, setSyncData] = useState(null);

    const formatIsoDate = (isoString) => {
        const date = new Date(isoString);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        return new Intl.DateTimeFormat('en-US', options).format(date);
    };

    const getAccountConnectStatus = async () => {
        try {
            const response = await axios.get(`${serverEndpoint}/amazon/get-account-status`, { withCredentials: true });
            setIsConnected(response.data.isConnected);
            setSyncInProgress(response.data.isSyncInProgress);
            setSyncData(response.data.syncData);
            setNotificationEnabled(response.data.notificationSubscriptionEnabled);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong' });
            setPartialLoad(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAccountConnectStatus();
    }, []);

    const handleAuthorize = async () => {
        setErrors({});
        if (!isConnected) {
            if (!marketplace) {
                setErrors({ marketplace: 'Please select a marketplace' });
                return;
            }
        }

        setPartialLoad(true);
        try {
            const response = await axios.get(`${serverEndpoint}/amazon/get-oauth-url`, {
                params: {
                    marketplace: marketplace
                },
                withCredentials: true
            });
            window.location.href = response.data;
        } catch (error) {
            setErrors({ message: 'Unable to connect to Amazon, please try again!' });
            console.error('Error fetching OAuth URL:', error);
            setPartialLoad(false);
        } finally {
            setMarketplace(null);
        }
    };

    const initiateSync = async () => {
        setSyncInProgress(true);
        try {
            await axios.get(`${serverEndpoint}/amazon/initiate-sync`, { withCredentials: true });
        } catch (error) {
            setErrors({ message: 'Unable to initaite sync, please try again!' });
            console.error(error);
            setSyncInProgress(false);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    if (errors.fatal) {
        return <ErrorComponent />;
    }

    return (
        <section className="h-100">
            <div className="container h-100">
                <div className="row justify-content-sm-center h-100 mt-4">
                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}
                    {message && (
                        <AutoDismissibleAlert type="success" message={message} />
                    )}
                    <h2 className="">Amazon Seller Account</h2>
                    <div className="col mb-2">

                        {!isConnected && (
                            <div className="card mt-2">
                                <ul className="list-group list-group-flush mt-2">
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-md-9 col-9">
                                                <div className="me-auto">
                                                    <div className="fw-bold mb-1">
                                                        <FaAmazon size={20} className="mb-0" /> Authorize Seller Account
                                                    </div>
                                                    <small className="text-muted">
                                                        {partialLoad ? (
                                                            <>
                                                                Please do not refresh or close this tab while authorization is in progress.
                                                            </>
                                                        ) : (
                                                            <>
                                                                Click the Authorize button to connect your account and grant the necessary permissions.
                                                                This will enable us to sync your amazon listing.
                                                            </>
                                                        )}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-3 text-end">
                                                <small className="text-body-secondary">
                                                    {partialLoad && (
                                                        <button className="btn btn-sm btn-outline-primary rounded-pill" type="button" disabled="">
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    )}

                                                    {!partialLoad && (
                                                        <button className="btn btn-sm btn-outline-primary rounded-pill" onClick={handleAuthorize}>
                                                            Authorize
                                                        </button>
                                                    )}
                                                </small>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-12 col-12">
                                                <div className="me-auto">
                                                    <div className="fw-bold mb-1">
                                                        Select Marketplace
                                                    </div>
                                                    <small className="text-muted">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className={errors.marketplace ? 'form-check-input is-invalid' : 'form-check-input'}
                                                                type="radio"
                                                                name="marketplaceOptions"
                                                                id="ukMarketplace"
                                                                defaultValue="UK"
                                                                onChange={handleMarketplaceChange}
                                                            />
                                                            <label className="form-check-label" htmlFor="ukMarketplace">
                                                                United Kingdom (UK)
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                className={errors.marketplace ? 'form-check-input is-invalid' : 'form-check-input'}
                                                                type="radio"
                                                                name="marketplaceOptions"
                                                                id="usMarketplace"
                                                                defaultValue="US"
                                                                onChange={handleMarketplaceChange}
                                                            />
                                                            <label className="form-check-label" htmlFor="usMarketplace">
                                                                United States of America (US)
                                                            </label>
                                                        </div>
                                                        <div className="invalid-feedback d-block">{errors?.marketplace}</div>
                                                    </small>
                                                    <div>
                                                        <small className="text-info">
                                                            <strong>Note: </strong> Once selected, the marketplace cannot be changed, and it will determine the currency displayed in Prep Master.
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        )}

                        {isConnected && (
                            <div className="card mt-2">
                                <ul className="list-group list-group-flush mt-2">
                                    <li className="list-group-item">
                                        <div className="row">
                                            <div className="col-md-9 col-9">
                                                <div className="me-auto">
                                                    <div className="fw-bold mb-1">
                                                        <FaAmazon size={20} className="mb-0" /> Sync Listings
                                                    </div>
                                                    <small className="text-muted">
                                                        {syncInProgress ? (
                                                            <>
                                                                The sync is in progress and may take up to 15 minutes to complete, depending on the number of listings in the Amazon account. Please refresh to check the status.
                                                            </>
                                                        ) : (
                                                            <>
                                                                {notificationEnabled ? (
                                                                    <>
                                                                        Your account is being synced with your Amazon Seller account automatically. 
                                                                        If you still wish to perform a manual sync, click the Sync button to initiate it.
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        Click the Sync button to import all your listings from Amazon. We will attempt to establish automatic syncing with Amazon, so you don’t have to do it manually every time.
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="col-md-3 col-3 text-end">
                                                <small className="text-body-secondary">
                                                    {syncInProgress && (
                                                        <button className="btn btn-sm btn-outline-primary rounded-pill" type="button" disabled="">
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    )}

                                                    {!syncInProgress && (
                                                        <>
                                                            <button className="btn btn-sm btn-outline-primary rounded-pill" onClick={initiateSync}>
                                                                Sync
                                                            </button>
                                                        </>
                                                    )}
                                                </small>

                                            </div>
                                        </div>

                                        {syncData && (
                                            <>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12 col-12">
                                                        <div className="me-auto">
                                                            <div className="fw-bold mb-1">
                                                                {syncInProgress ? (
                                                                    <>Sync Result</>
                                                                ) : (
                                                                    <>Last Sync Result</>
                                                                )}
                                                            </div>
                                                            <small className="text-muted">
                                                                <strong>Status:</strong> {syncData.sync_status}
                                                                <br />
                                                                <strong>Time:</strong> {formatIsoDate(syncData.last_updated)}
                                                                <br />
                                                                <strong>Remark:</strong> {syncData.remark}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </section>
    );
}

export default AmazonSellerAccount;