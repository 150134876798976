import React, { useState, useRef, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import { CCol, CRow } from '@coreui/react';
import ErrorComponent from '../../components/Error';
import { useSelector } from "react-redux";
import AutoDismissibleAlert from '../../components/AutoDismissibleAlert';

const CustomOption = ({ innerRef, innerProps, data }) => (
    <div ref={innerRef} {...innerProps} className='my-1' style={{ display: 'flex', alignItems: 'center' }}>
        <img
            src={data.imageUrl}
            alt={data.label}
            style={{ width: 30, height: 30, marginRight: 10 }}
        />
        {data.label}
    </div>
);

function AddBundle({ listing }) {
    const userDetails = useSelector((state) => state.userDetails);
    const fileInputRef = useRef(null)
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: null,
        instockQuantity: 0,
        imageKey: '',
        imageUrl: '',
        bundleImage: '',
        cog: 0,
        roi: 0,
        margin: 0,
        profit: 0,
        salePrice: 0,
        listings: listing ? [listing] : [],
        components: []
    });
    const [previewUrl, setPreviewUrl] = useState(null);
    const [timeoutId, setTimeoutId] = useState(null);

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.title?.length === 0) {
            newErrors.title = 'Please provide a valid Title';
            isValid = false;
        }

        if (formData.components?.length > 0) {
            formData.components.map((component) => {
                if (component.quantity <= 0) {
                    if (!newErrors.components) {
                        newErrors.components = {};
                    }
                    newErrors.components[component.value] = 'Quantity must be greater than 0';
                    isValid = false;
                }
            });
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: value };

            // Calculate ROI, Margin if salePrice or cog or profit changes
            if (name === "salePrice" || name === "cog" || name === "profit") {
                const salePrice = parseFloat(updatedData.salePrice) || 0;
                const cog = parseFloat(updatedData.cog) || 0;
                const profit = parseFloat(updatedData.profit) || 0;

                // Calculate Margin, ROI
                const margin = salePrice > 0 ? (profit / salePrice) * 100 : 0;
                const roi = cog > 0 ? (profit / cog) * 100 : 0;

                // Update the calculated fields
                updatedData.margin = margin.toFixed(2);
                updatedData.roi = roi.toFixed(2);
            }

            return updatedData;
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Check file size (for example, 5MB limit)
            const maxSizeInBytes = 5 * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                setErrors({ ...errors, bundleImage: 'File size should not exceed 5MB' });
                return;
            }

            // Clear previous errors if any
            setErrors({ ...errors, bundleImage: null });

            // Set the file and create a preview URL
            setFormData({
                ...formData,
                imageUrl: '',
                imageKey: '',
                bundleImage: file,
            });

            // Generate and store the preview URL
            const preview = URL.createObjectURL(file);
            setPreviewUrl(preview);
        }
    };

    const handleListingChange = (selected) => {
        if (selected) {
            setFormData({
                ...formData,
                title: selected.label,
                salePrice: selected.salePrice,
                imageKey: selected.imageKey,
                imageUrl: selected.imageUrl,
                listings: [selected]
            });
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            setPreviewUrl(null);
        } else {
            setFormData({
                ...formData,
                title: '',
                imageKey: '',
                imageUrl: '',
                salePrice: 0,
                listings: [],
            });
        }
    };

    const handleComponentsChange = (selected) => {
        setFormData({
            ...formData,
            components: selected
        });
    };

    const handleSubmit = async (e) => {
        setMessage(null);
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);

            try {
                const transformedListings = formData.listings.reduce((acc, item) => {
                    acc[item.value] = {
                        title: item.label,
                        image_key: item.imageKey
                    };
                    return acc;
                }, {});

                const transformedComponents = formData.components.reduce((acc, item) => {
                    acc[item.value] = {
                        title: item.label,
                        image_key: item.imageKey,
                        quantity: item.quantity
                    };
                    return acc;
                }, {});

                const requestBody = {
                    title: formData.title,
                    description: formData.description === '' ? null : formData.description,
                    instock_quantity: formData.instockQuantity === '' ? 0 : formData.instockQuantity,
                    cog: formData.cog === '' ? 0 : formData.cog,
                    roi: formData.roi === '' ? 0 : formData.roi,
                    margin: formData.margin === '' ? 0 : formData.margin,
                    profit: formData.profit === '' ? 0 : formData.profit,
                    sale_price: formData.salePrice === '' ? 0 : formData.salePrice,
                    listings: transformedListings,
                    components: transformedComponents
                };

                if (formData.imageKey.length > 0) {
                    requestBody.image_key = formData.imageKey;
                } else if (formData.bundleImage) {
                    setMessage('Uploading image...');
                    const uploadData = await axios.get(`${serverEndpoint}/images/get-upload-url/bundles`, {
                        params: {
                            contentType: formData.bundleImage.type
                        },
                        withCredentials: true
                    });

                    const uploadUrl = uploadData.data.uploadURL;

                    await axios.put(uploadUrl, formData.bundleImage, {
                        headers: {
                            'Content-Type': formData.bundleImage.type
                        }
                    })
                    requestBody.image_key = uploadData.data.key;
                }

                if (userDetails.prepCenterId) {
                    requestBody.prep_center_id = userDetails.prepCenterId;
                }
                
                axios.post(`${serverEndpoint}/bundles/create-bundle`, requestBody, {
                    withCredentials: true
                });
                setMessage('Bundle Added!');
                setFormData({
                    title: '',
                    description: '',
                    instockQuantity: 0,
                    bundleImage: '',
                    imageKey: '',
                    imageUrl: '',
                    cog: 0.0,
                    roi: 0.0,
                    margin: 0.0,
                    profit: 0.0,
                    salePrice: 0.0,
                    listings: [],
                    components: []
                });
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setPreviewUrl(null);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setMessage(null);
                setErrors({ message: "Unable to add Bundle, please try again!" });
                setLoading(false);
            }

        }
    };

    const getListings = async (searchString) => {
        try {
            const response = await axios.get(`${serverEndpoint}/listings/get-listings/${searchString}`, { withCredentials: true });
            const listings = response.data?.documents;
            return listings.map(item => ({
                value: item.sk.split('L#')[1],
                label: item.title,
                imageKey: item.image_key,
                imageUrl: item.image_url,
                salePrice: item.sale_price
            }));
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again!' });
            return [];
        }
    };

    const getComponents = async (searchString) => {
        try {
            const response = await axios.get(`${serverEndpoint}/components/get-components/${searchString}`, { withCredentials: true });
            return response.data?.documents.map(item => ({
                value: item.sk.split('C#')[1],
                label: item.title,
                imageKey: item.image_key,
                imageUrl: item.image_url,
                quantity: 1
            }));
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again!' });
            return [];
        }
    };

    const fetchOptionsWithDelay = useCallback((type) => (inputValue, callback) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(async () => {
            if (!inputValue) return;

            try {
                let items = [];
                if (type === 'LISTING') {
                    items = await getListings(inputValue);
                } else if (type === 'COMPONENT') {
                    items = await getComponents(inputValue);
                }
                callback(items);
            } catch (error) {
                console.log(error);
                callback([]);
            }
        }, 500);

        setTimeoutId(newTimeoutId);
    }, [timeoutId]);

    const handleQuantityChange = (itemValue, quantity) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            components: prevFormData.components.map((component) =>
                component.value === itemValue ? { ...component, quantity: quantity } : component
            ),
        }));
    };

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <div className="container">
            <CRow>
                <CCol xs={12}>
                    <div className="">
                        <h2>Add Bundle</h2>
                        <p className="text-secondary mb-2">Create customized bundles</p>
                    </div>
                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}

                    {message && (
                        <AutoDismissibleAlert type="success" message={message} />
                    )}

                    <form onSubmit={handleSubmit} className='border rounded bg-white p-4'>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="listings">
                                Listing
                            </label>
                            <AsyncSelect
                                cacheOptions
                                loadOptions={fetchOptionsWithDelay("LISTING")}
                                isClearable={true}
                                defaultOptions={[]}
                                value={formData.listings}
                                onChange={handleListingChange}
                                placeholder="Search for Listing..."
                                noOptionsMessage={() => "No Listings found"}
                                className={errors.itemToPrepare ? 'is-invalid' : ''}
                                classNamePrefix="react-select"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        '&:hover': {
                                            borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        },
                                    }),
                                }}
                                components={{ Option: CustomOption }}
                            />
                            <div className="invalid-feedback">{errors?.listings}</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="title">
                                Title
                            </label>
                            <input id="title" type="text" className={errors.title ? 'form-control is-invalid' : 'form-control'}
                                name="title" onChange={handleChange} value={formData.title} autoFocus
                            />
                            <div className="invalid-feedback">{errors?.title}</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="description">
                                Description
                            </label>
                            <textarea
                                id="description"
                                name="description"
                                className={errors.description ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleChange}
                                value={formData.description}
                                rows="4" // Adjust the rows as needed
                            />
                            <div className="invalid-feedback">{errors?.description}</div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label className="mb-2 text-muted" htmlFor="salePrice">Sale Price</label>
                                <input id="salePrice" type="number" name="salePrice"
                                    className={errors.salePrice ? 'form-control is-invalid' : 'form-control'}
                                    onChange={handleChange} value={formData.salePrice} />
                                <div className="invalid-feedback">{errors?.salePrice}</div>
                            </div>

                            <div className="col-md-4 mb-3">
                                <label className="mb-2 text-muted" htmlFor="cog">COG (Cost of Goods)</label>
                                <input id="cog" type="number" name="cog"
                                    className={errors.cog ? 'form-control is-invalid' : 'form-control'}
                                    onChange={handleChange} value={formData.cog} />
                                <div className="invalid-feedback">{errors?.cog}</div>
                            </div>

                            <div className="col-md-4 mb-3">
                                <label className="mb-2 text-muted" htmlFor="margin">Margin (%)</label>
                                <input id="margin" type="number" name="margin"
                                    className={errors.margin ? 'form-control is-invalid' : 'form-control'}
                                    onChange={handleChange} value={formData.margin} disabled />
                                <div className="invalid-feedback">{errors?.margin}</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label className="mb-2 text-muted" htmlFor="roi">ROI (%)</label>
                                <input id="roi" type="number" name="roi"
                                    className={errors.roi ? 'form-control is-invalid' : 'form-control'}
                                    onChange={handleChange} value={formData.roi} disabled />
                                <div className="invalid-feedback">{errors?.roi}</div>
                            </div>

                            <div className="col-md-4 mb-3">
                                <label className="mb-2 text-muted" htmlFor="profit">Profit</label>
                                <input id="profit" type="number" name="profit"
                                    className={errors.profit ? 'form-control is-invalid' : 'form-control'}
                                    onChange={handleChange} value={formData.profit} />
                                <div className="invalid-feedback">{errors?.profit}</div>
                            </div>

                            <div className="col-md-4 mb-3">
                                <label className="mb-2 text-muted" htmlFor="instockQuantity">Instock Quantity</label>
                                <input id="instockQuantity" type="number" name="instockQuantity"
                                    className={errors.instockQuantity ? 'form-control is-invalid' : 'form-control'}
                                    onChange={handleChange} value={formData.instockQuantity} />
                                <div className="invalid-feedback">{errors?.instockQuantity}</div>
                            </div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="components">
                                Components
                            </label>
                            <AsyncSelect
                                cacheOptions
                                isMulti
                                loadOptions={fetchOptionsWithDelay("COMPONENT")}
                                defaultOptions={[]}
                                value={formData.components}
                                onChange={handleComponentsChange}
                                placeholder="Search for Component..."
                                noOptionsMessage={() => "No Component found"}
                                className={errors.components ? 'is-invalid' : ''}
                                classNamePrefix="react-select"
                                styles={{
                                    control: (base, state) => ({
                                        ...base,
                                        borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        '&:hover': {
                                            borderColor: errors.itemToPrepare ? '#dc3545' : base.borderColor,
                                        },
                                    }),
                                }}
                            />
                        </div>

                        {formData.components?.length > 0 && (
                            <div className="mb-3">
                                <label className="mb-2 text-muted" htmlFor="bundleImage">
                                    Component Quantity
                                </label>
                                <div className='container border rounded p-2'>
                                    {formData.components.map((item) => (
                                        <div key={item.value} className='mb-2'>
                                            <label className="text-muted" htmlFor={item.value}>
                                                {item.label}
                                            </label>
                                            <input
                                                id={item.value}
                                                name={item.value}
                                                type="number"
                                                value={item.quantity || ""}
                                                onChange={(e) => handleQuantityChange(item.value, e.target.value)}
                                                placeholder="Enter quantity"
                                                className={errors.components?.[item.value] ? 'form-control is-invalid' : 'form-control'}
                                            />
                                            <div className="invalid-feedback">{errors?.components?.[item.value]}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}


                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="bundleImage">
                                Bundle Image
                            </label>
                            <input id="bundleImage" type="file" name="bundleImage"
                                className={errors.bundleImage ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleImageChange} accept="image/*"
                                ref={fileInputRef}
                            />
                            <div className="invalid-feedback">{errors?.bundleImage}</div>
                        </div>

                        {previewUrl && (
                            <div className="mt-3">
                                <p>Preview:</p>
                                <img src={previewUrl} alt="Preview" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                            </div>
                        )}

                        {formData.imageKey?.length > 0 && (
                            <div className="mt-3">
                                <p>Preview:</p>
                                <img src={formData.imageUrl} alt="Preview" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                            </div>
                        )}

                        <div className="align-items-center d-flex">
                            {loading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!loading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Add
                                </button>
                            )}
                        </div>
                    </form>
                </CCol>
            </CRow>
        </div>
    );
}

export default AddBundle;
