import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint } from "../../util/config";
import { Card, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorComponent from "../../components/Error";
import { DEFAULT_PAGE_SIZE, USER_ROLES } from "../../util/constants";
import { useSelector } from 'react-redux';
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import { FaFileExcel } from "react-icons/fa";
import Select from 'react-select';
import { FaCaretRight, FaCaretDown } from "react-icons/fa";

function ViewInstockItems() {
    const userDetails = useSelector((state) => state.userDetails);
    const [isComponentsView, setIsComponentsView] = useState(false);
    const switchView = (tab) => setIsComponentsView(tab);

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsLoading, setItemsLoading] = useState(false);
    const [moreDataAvailable, setMoreDataAvailable] = useState(false);
    const [startingFrom, setStartingFrom] = useState(null);
    const [noOfDocuments, setNoOfDocuments] = useState(null);
    const [isFilterExanded, setIsFilterExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsFilterExpanded(!isFilterExanded);
    };

    const getInstockItems = async (reset = false, type = 'Listings') => {
        if (itemsLoading) return;

        try {
            setItemsLoading(true);
            const params = {
                startingFrom: reset ? 0 : startingFrom,
                noOfDocuments: noOfDocuments || DEFAULT_PAGE_SIZE,
                type: type
            };

            let response;
            if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
                if (selectedPrepMasterAccount !== null) {
                    params.emailId = selectedPrepMasterAccount.value;
                    response = await axios.get(`${serverEndpoint}/instock/get/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                } else {
                    response = await axios.get(`${serverEndpoint}/instock/prep-center/${userDetails.masterAccountId}/get/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                }
            } else {
                response = await axios.get(`${serverEndpoint}/instock/get/${searchQuery}`, {
                    params: params,
                    withCredentials: true,
                });
            }

            let updatedItems;
            if (type === 'Listings') {
                updatedItems = [
                    ...response.data?.documents.map(item => ({
                        ...item,
                        url: `/listing/${item.sk.split('L#')[1]}${userDetails.role === USER_ROLES.PREP_CENTER_ADMIN ? `/${encodeURIComponent(item.pk.split('#')[0])}` : ''}`
                    }))
                ];
            } else {
                updatedItems = [
                    ...response.data?.documents.map(item => ({
                        ...item,
                        url: `/component/${item.sk.split('C#')[1]}${userDetails.role === USER_ROLES.PREP_CENTER_ADMIN ? `/${encodeURIComponent(item.pk.split('#')[0])}` : ''}`
                    }))
                ];
            }

            const pagination = response?.data?.pagination || {};
            if (pagination.currentPage === pagination.totalPages || pagination.currentPage > pagination.totalPages) {
                setMoreDataAvailable(false);
            } else {
                setMoreDataAvailable(true);
            }
            setDisplayData((prev) => reset ? updatedItems : [...prev, ...updatedItems]);
            setStartingFrom(pagination.startingFrom + pagination.noOfDocuments);
            setNoOfDocuments(pagination.noOfDocuments);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
        } finally {
            setLoading(false);
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getInstockItems();
    }, []);

    const [selectedPrepMasterAccount, setSelectedPrepMasterAccount] = useState(null);
    const [prepMasterAccountsLoading, setPrepMasterAccountsLoading] = useState(true);
    const [prepMasterAccounts, setPrepMasterAccounts] = useState([]);

    const handlePrepMasterAccountChange = async (option) => {
        setSelectedPrepMasterAccount(option);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const type = isComponentsView ? 'Components' : 'Listings';
            getInstockItems(true, type);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, selectedPrepMasterAccount, isComponentsView]);

    const getPrepMasterAccounts = async () => {
        try {
            const response = await axios.post(`${serverEndpoint}/user/get-users`, {
                master_account_id: userDetails.masterAccountId
            }, { withCredentials: true });
            const accounts = response?.data?.users?.map((account) => ({
                value: account.email,
                label: account.email,
            }));
            setPrepMasterAccounts(accounts);
        } catch (error) {
            console.log(error);
            setErrors({ message: "Unable to fetch Prep Master Accounts for Filter" });
        } finally {
            setPrepMasterAccountsLoading(false);
        }
    };

    useEffect(() => {
        if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
            getPrepMasterAccounts();
        } else {
            setPrepMasterAccountsLoading(false);
        }
    }, []);

    // Excel export section
    const [isExcelReportInProgress, setIsExcelReportInProgress] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const handleGenerateExcel = async () => {
        setMessage(null);

        let payload = {};
        if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
            payload.prep_center_id = userDetails.masterAccountId
        }

        const params = {
            excelType: "instocks",
        };

        try {
            setIsExcelReportInProgress(true);
            const response = await axios.post(`${serverEndpoint}/async/generate-excelsheet`, payload, {
                params: params,
                withCredentials: true
            });
            if (response.status === 200) {
                setMessage(
                    <>
                        Your export request has been submitted successfully!<br />
                        Request ID: <strong>{response.data.data.uuid}. </strong>
                        You can track the progress of your export{" "}
                        <Link to={`/reports`}>here</Link>.
                    </>
                );
            } else {
                setErrors({ message: 'Failed to generate excel report, please try again' });
            }
        } catch (error) {
            console.error('Error generating Excel sheet:', error);
            setErrors({ message: 'Failed to generate excel report, please try again' });
        } finally {
            setIsExcelReportInProgress(false);
        }
    };


    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container p-4">
                    <div className="row align-items-center ms-1">
                        {errors.message && (
                            <AutoDismissibleAlert type="danger" message={errors.message} />
                        )}
                        {message && (
                            <AutoDismissibleAlert type="success" message={message} />
                        )}
                        {/* Left Section: Title and Subtitle */}
                        <div className="col">
                            <h2 className="mb-0">Instock Items</h2>
                            <p className="text-secondary mb-0">View all the components and listings that are in-stock</p>
                        </div>

                        {/* Right Section: Button */}
                        <div className="col-auto">
                            {isExcelReportInProgress && (
                                <button className="btn btn-success text-white ms-auto btn-sm d-block d-md-inline" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!isExcelReportInProgress && (
                                <button className="btn btn-success text-white ms-auto btn-sm d-block d-md-inline" type="submit" onClick={handleGenerateExcel}>
                                    <FaFileExcel className="mb-1" /> Export
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="ps-5 p-3 m-1 mt-3 mb-0 border rounded bg-white">
                        Listings
                        <div className="d-inline-flex align-items-center mx-2">
                            <Form.Check
                                type="switch"
                                label=""
                                checked={isComponentsView}
                                onChange={(e) => switchView(e.target.checked)}
                            />
                        </div>
                        Components
                    </div>

                    <div className="row m-1 accordion mt-3" id="accordionExample">
                        {/* Accordion Item 1 */}
                        <div className="accordion-item">
                            <h6 className="accordion-header py-3" id="headingOne">
                                <div className="d-flex align-items-center w-100">
                                    <div className="form-check d-flex align-items-center">
                                        <Link
                                            className="text-dark btn-link cursor-pointer text-decoration-none"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                            aria-controls="collapseOne" onClick={toggleAccordion}>
                                            {isFilterExanded ? (
                                                <FaCaretDown className="text-dark mb-1" size={18} />
                                            ) : (
                                                <FaCaretRight className="text-dark mb-1" size={18} />
                                            )}
                                            Search & Filter
                                        </Link>
                                    </div>
                                </div>
                            </h6>

                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <hr className='m-0' />
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col">
                                            <label for="exampleFormControlInput1" className="form-label">Search</label>
                                            <input type="text" placeholder="Search by title or Description" value={searchQuery}
                                                className="form-control" id="exampleFormControlInput1" onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                        <div className="row mt-4">
                                            <div className="col">
                                                <label for="exampleFormControlInput1" className="form-label">Filter By: Prep Master Accounts</label>
                                                <Select
                                                    options={prepMasterAccounts}
                                                    isClearable={true}
                                                    placeholder="Select"
                                                    isLoading={itemsLoading || prepMasterAccountsLoading}
                                                    isDisabled={itemsLoading || prepMasterAccountsLoading}
                                                    value={selectedPrepMasterAccount}
                                                    onChange={handlePrepMasterAccountChange}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Row className="mt-3 text-center p-2 bg-white border rounded m-1">
                        {displayData?.length === 0 && !itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <p>No results found</p>
                            </div>
                        )}

                        {displayData.map((item) => (
                            <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={item.sk}>
                                <Card className="position-relative h-100 d-flex flex-column">
                                    <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                        <Link to={item.url}>
                                            <img src={item.image_url ? item.image_url : '/images/default-image.jpg'}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                    e.target.src = "/images/default-image.jpg";
                                                }}
                                                alt={item.title} className="rounded" style={{
                                                    maxHeight: '210px',
                                                    width: '100%',
                                                    objectFit: 'cover'
                                                }} />
                                        </Link>
                                    </Card.Body>
                                    <Card.Body className="flex-grow-1 pt-0">
                                        <Link to={item.url} className="text-body text-decoration-none hover:text-primary">
                                            <h5 className="w-100" title={item.title}>
                                                {item.title.length > 20 ? `${item.title.substring(0, 20)}...` : item.title}
                                            </h5>
                                        </Link>
                                        {item.asin && (
                                            <h6 className="text-secondary"><strong>ASIN:</strong> {item.asin}</h6>
                                        )}
                                        <h6 className="text-success"><strong>Instock Quantity:</strong> {item.instock_quantity}</h6>
                                        {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                            <p className="text-muted mb-0"><strong>{item.pk.split('#')[0]}</strong></p>
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Col className="mt-5">
                        {moreDataAvailable && (
                            <div className="row justify-content-center mt-4">
                                <div className="col-auto">

                                    {itemsLoading ? (
                                        <button className="btn btn-primary" type="button" disabled="">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    ) : (
                                        <button onClick={() => getInstockItems(false, isComponentsView ? 'Components' : 'Listings')} className="btn btn-primary">
                                            Load More
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </Col>
                </div>
            </section>
        </>
    );
}

export default ViewInstockItems;