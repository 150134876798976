import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint, CURRENCY_TO_SYMBOL_MAP } from "../../util/config";
import { Card, Col, Row, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsTelephoneInbound } from "react-icons/bs";
import { LuListTodo } from "react-icons/lu";
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import { DEFAULT_PAGE_SIZE, USER_ROLES } from "../../util/constants";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import Select from 'react-select';
import { FaCaretRight, FaCaretDown } from "react-icons/fa";

function ViewListings() {
    const userDetails = useSelector((state) => state.userDetails);
    const currencySymbol = CURRENCY_TO_SYMBOL_MAP[userDetails.accountCurrency] || '£';
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [itemsLoading, setItemsLoading] = useState(false);
    const [moreDataAvailable, setMoreDataAvailable] = useState(false);
    const [startingFrom, setStartingFrom] = useState(null);
    const [noOfDocuments, setNoOfDocuments] = useState(null);
    const [isFilterExanded, setIsFilterExpanded] = useState(false);

    const toggleAccordion = () => {
        setIsFilterExpanded(!isFilterExanded);
    };

    const getListings = async (reset = false) => {
        if (itemsLoading) return;

        try {
            setItemsLoading(true);
            const params = {
                startingFrom: reset ? 0 : startingFrom,
                noOfDocuments: noOfDocuments || DEFAULT_PAGE_SIZE,
            };

            let response;
            if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
                if (selectedPrepMasterAccount !== null) {
                    params.emailId = selectedPrepMasterAccount.value;
                    response = await axios.get(`${serverEndpoint}/listings/get-listings/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                } else {
                    response = await axios.get(`${serverEndpoint}/listings/prep-center/${userDetails.masterAccountId}/get-listings/${searchQuery}`, {
                        params: params,
                        withCredentials: true,
                    });
                }
            } else {
                response = await axios.get(`${serverEndpoint}/listings/get-listings/${searchQuery}`, {
                    params: params,
                    withCredentials: true,
                });
            }

            const newDocuments = response?.data?.documents || [];
            const pagination = response?.data?.pagination || {};
            if (pagination.currentPage === pagination.totalPages || pagination.currentPage > pagination.totalPages) {
                setMoreDataAvailable(false);
            } else {
                setMoreDataAvailable(true);
            }
            setDisplayData((prev) => reset ? newDocuments : [...prev, ...newDocuments]);
            setStartingFrom(pagination.startingFrom + pagination.noOfDocuments);
            setNoOfDocuments(pagination.noOfDocuments);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
        } finally {
            setLoading(false);
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getListings();
    }, []);

    const [selectedPrepMasterAccount, setSelectedPrepMasterAccount] = useState(null);
    const [prepMasterAccountsLoading, setPrepMasterAccountsLoading] = useState(true);
    const [prepMasterAccounts, setPrepMasterAccounts] = useState([]);

    const handlePrepMasterAccountChange = async (option) => {
        setSelectedPrepMasterAccount(option);
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getListings(true);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, selectedPrepMasterAccount]);

    const getPrepMasterAccounts = async () => {
        try {
            const response = await axios.post(`${serverEndpoint}/user/get-users`, {
                master_account_id: userDetails.masterAccountId
            }, { withCredentials: true });
            const accounts = response?.data?.users?.map((account) => ({
                value: account.email,
                label: account.email,
            }));
            setPrepMasterAccounts(accounts);
        } catch (error) {
            console.log(error);
            setErrors({ message: "Unable to fetch Prep Master Accounts for Filter" });
        } finally {
            setPrepMasterAccountsLoading(false);
        }
    };

    useEffect(() => {
        if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
            getPrepMasterAccounts();
        } else {
            setPrepMasterAccountsLoading(false);
        }
    }, []);

    const [markInboundLoading, setMarkInboundLoading] = useState(false);
    const [showMarkInboundModal, setShowMarkInboundModal] = useState(false);
    const [markInboundFormData, setMarkInboundFormData] = useState({
        qtyInbound: '',
        trackingNumber: ''
    });

    const handleClose = () => setShowMarkInboundModal(false);
    const handleShow = (inboundItem) => {
        if (!hasPermission(userDetails, "Inbound", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }
        setErrors({});
        setMarkInboundFormData({
            inboundQty: '',
            itemId: inboundItem.sk.split('L#')[1],
            title: inboundItem.title,
            imageKey: inboundItem.image_key,
            imageUrl: inboundItem.image_url,
            trackingNumber: ''
        });
        setShowMarkInboundModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMarkInboundFormData({
            ...markInboundFormData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (markInboundFormData.inboundQty <= 0) {
            setErrors({
                inboundQty: 'Please enter a valid Quantity'
            });
            return;
        }

        setMarkInboundLoading(true);
        const listings = {};
        listings[markInboundFormData.itemId] = {
            title: markInboundFormData.title,
            image_key: markInboundFormData.imageKey,
            image_url: markInboundFormData.imageUrl,
            inboundQty: markInboundFormData.inboundQty,
            trackingNumber: markInboundFormData.trackingNumber
        };

        axios.post(`${serverEndpoint}/inbound/create`, {
            listings: listings,
            components: {},
            ...(userDetails.prepCenterId && { prep_center_id: userDetails.prepCenterId })
        }, { withCredentials: true })
            .then(response => {
                setMarkInboundLoading(false);
                handleClose();
            }).catch(error => {
                console.log(error);
                setErrors({ message: "Unable to mark listing inbound!" });
            });
    };


    const [addToDoLoading, setAddTodoLoading] = useState(false);
    const [showAddToDoModal, setShowAddToDoModal] = useState(false);
    const [addToDoFormData, setAddToDoFormData] = useState({
        quantityToPrepare: 0
    });

    const handleAddToDoClose = () => setShowAddToDoModal(false);
    const handleAddToDoShow = (todoItem) => {
        if (!hasPermission(userDetails, "Task Management", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }
        setErrors({});
        setAddToDoFormData({
            quantityToPrepare: 0,
            itemToPrepare: todoItem
        });
        setShowAddToDoModal(true);
    }

    const handleAddTodoFormChange = (e) => {
        const { name, value } = e.target;
        setAddToDoFormData({
            ...addToDoFormData,
            [name]: value,
        });
    };

    const handleAddTodoSubmit = async (e) => {
        e.preventDefault();
        if (addToDoFormData.quantityToPrepare <= 0) {
            setErrors({
                quantityToPrepare: 'Please enter a valid Quantity'
            });
            return;
        }

        setAddTodoLoading(true);
        for (const key in addToDoFormData.itemToPrepare.bundles) {
            delete addToDoFormData.itemToPrepare.bundles[key].image_url;
        }
        axios.post(`${serverEndpoint}/tasks/create-task`, {
            title: addToDoFormData.itemToPrepare.title,
            quantity_to_prepare: addToDoFormData.quantityToPrepare,
            listings: {},
            components: {},
            bundles: addToDoFormData.itemToPrepare.bundles ? addToDoFormData.itemToPrepare.bundles : {},
            image_key: addToDoFormData.itemToPrepare.image_key,
            item_id: addToDoFormData.itemToPrepare.sk,
            prep_center_id: userDetails.prepCenterId
        }, { withCredentials: true })
            .then(response => {
                setAddTodoLoading(false);
                handleAddToDoClose();
            }).catch(error => {
                console.log(error);
                handleAddToDoClose();
                setErrors({ message: "Unable to create ToDo task!" });
            });
    };


    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container p-4">
                    <div className="ms-1">
                        {errors.message && (
                            <AutoDismissibleAlert type="danger" message={errors.message} />
                        )}
                        <h2>Listings</h2>
                        <p className="text-secondary mb-2">View & Manage all your Amazon listings</p>
                    </div>

                    <div className="row m-1 accordion mt-3" id="accordionExample">
                        {/* Accordion Item 1 */}
                        <div className="accordion-item">
                            <h6 className="accordion-header py-3" id="headingOne">
                                <div className="d-flex align-items-center w-100">
                                    <div className="form-check d-flex align-items-center">
                                        <Link
                                            className="text-dark btn-link cursor-pointer text-decoration-none"
                                            data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false"
                                            aria-controls="collapseOne" onClick={toggleAccordion}>
                                            {isFilterExanded ? (
                                                <FaCaretDown className="text-dark mb-1" size={18} />
                                            ) : (
                                                <FaCaretRight className="text-dark mb-1" size={18} />
                                            )}
                                            Search & Filter
                                        </Link>
                                    </div>
                                </div>
                            </h6>

                            <div
                                id="collapseOne"
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                            >
                                <hr className='m-0' />
                                <div className="accordion-body">
                                    <div className="row">
                                        <div className="col">
                                            <label for="exampleFormControlInput1" className="form-label">Search</label>
                                            <input type="text" placeholder="Search by title or Description" value={searchQuery}
                                                className="form-control" id="exampleFormControlInput1" onChange={(e) => setSearchQuery(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                        <div className="row mt-4">
                                            <div className="col">
                                                <label for="exampleFormControlInput1" className="form-label">Filter By: Prep Master Accounts</label>
                                                <Select
                                                    options={prepMasterAccounts}
                                                    isClearable={true}
                                                    placeholder="Select"
                                                    isLoading={itemsLoading || prepMasterAccountsLoading}
                                                    isDisabled={itemsLoading || prepMasterAccountsLoading}
                                                    value={selectedPrepMasterAccount}
                                                    onChange={handlePrepMasterAccountChange}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>


                        </div>
                    </div>

                    <Row className="mt-3 text-center p-2 bg-white border rounded m-1">
                        {displayData?.length === 0 && !itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <p>No results found</p>
                            </div>
                        )}

                        {displayData.map((listing) => (
                            <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={listing.sk}>
                                <Card className="position-relative h-100 d-flex flex-column">
                                    <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                        <Link to={`/listing/${listing.sk.split('L#')[1]}${userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && listing.pk ? `/${encodeURIComponent(listing.pk.split('#')[0])}` : ''}`}>
                                            <img src={listing.image_url ? listing.image_url : '/images/default-image.jpg'}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                    e.target.src = "/images/default-image.jpg";
                                                }}
                                                alt={listing.title} className="rounded" style={{
                                                    maxHeight: '210px',
                                                    width: '100%',
                                                    objectFit: 'cover'
                                                }} />
                                        </Link>
                                    </Card.Body>
                                    <Card.Body className="flex-grow-1 pt-0">
                                        <Link to={`/listing/${listing.sk.split('L#')[1]}${userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && listing.pk ? `/${encodeURIComponent(listing.pk.split('#')[0])}` : ''}`} className="text-body text-decoration-none hover:text-primary">
                                            <h5 className="w-100" title={listing.title}>
                                                {listing.title?.length > 20 ? `${listing.title?.substring(0, 20)}...` : listing.title}
                                            </h5>
                                        </Link>
                                        {listing.asin && (
                                            <h6 className="text-secondary"><strong>ASIN:</strong> {listing.asin}</h6>
                                        )}
                                        <h5 className="text-primary"><strong>{currencySymbol}{listing.sale_price}</strong></h5>
                                        {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                                            <p className="text-muted mb-0"><strong>{listing.pk.split('#')[0]}</strong></p>
                                        )}
                                    </Card.Body>
                                    {(hasPermission(userDetails, "Inbound", "Add") || hasPermission(userDetails, "Task Management", "Add")) && (
                                        <div className="d-flex justify-content-center mb-4">
                                            {hasPermission(userDetails, "Inbound", "Add") && (
                                                <div className="mx-2">
                                                    <button className="btn btn-sm btn-outline-success" onClick={() => handleShow(listing)}>
                                                        <BsTelephoneInbound size={15} /> Mark Inbound
                                                    </button>
                                                </div>
                                            )}

                                            {hasPermission(userDetails, "Task Management", "Add") && (
                                                <div className="mx-2">
                                                    <button className="btn btn-sm btn-outline-info" onClick={() => handleAddToDoShow(listing)}>
                                                        <LuListTodo size={18} className="mb-0" /> Add to ToDo
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Col className="mt-5">
                        {moreDataAvailable && (
                            <div className="row justify-content-center mt-4">
                                <div className="col-auto">

                                    {itemsLoading ? (
                                        <button className="btn btn-primary" type="button" disabled="">
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    ) : (
                                        <button onClick={() => getListings()} className="btn btn-primary">
                                            Load More
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </Col>
                </div>
            </section>

            <Modal show={showMarkInboundModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Inbound Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="inboundQty">
                                {markInboundFormData.title}
                            </label>
                            <input id="inboundQty" type="number" className={errors?.inboundQty ? 'form-control is-invalid' : 'form-control'}
                                name="inboundQty" value={markInboundFormData.inboundQty} placeholder="Quantity" autoFocus onChange={handleChange}
                            />
                            <div className="invalid-feedback">Please provide a valid Quantity</div>
                            <input id="trackingNumber" type="text" className='form-control mt-2'
                                name="trackingNumber" value={markInboundFormData.trackingNumber} placeholder="Tracking Number (Optional)" onChange={handleChange}
                            />
                        </div>

                        <div className="align-items-center d-flex">
                            {markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddToDoModal} onHide={handleAddToDoClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create ToDo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddTodoSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="quantityToPrepare">
                                Quantity to Prepare
                            </label>
                            <input id="quantityToPrepare" type="number" className={errors?.quantityToPrepare ? 'form-control is-invalid' : 'form-control'}
                                name="quantityToPrepare" value={addToDoFormData.quantityToPrepare} autoFocus onChange={handleAddTodoFormChange}
                            />
                            <div className="invalid-feedback">Please provide a valid Quantity</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {addToDoLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!addToDoLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Create
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewListings;
