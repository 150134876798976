import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    CCloseButton, CSidebar, CSidebarBrand, CSidebarHeader,
    CSidebarNav, CNavTitle, CNavItem, CNavLink, CNavGroup
} from '@coreui/react';
import { TbHexagonLetterJ } from "react-icons/tb";
import { NavLink } from 'react-router-dom';
import { BsTelephoneInbound } from "react-icons/bs";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FaUsersViewfinder } from "react-icons/fa6";
import { MdAddBusiness } from "react-icons/md";
import { LuComponent, LuListTodo } from "react-icons/lu";
import { LiaSitemapSolid } from "react-icons/lia";
import { CiViewList } from "react-icons/ci";
import { GrBundle } from "react-icons/gr";
import { FaUsers, FaTasks } from "react-icons/fa";
import { MdOutlineInventory } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { USER_ROLES } from '../util/constants';
import { hasPermission } from '../util/permissionsEvaluator';

function AppSidebar() {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)

    const hasListingViewPermission = hasPermission(userDetails, "Amazon Listing", "View");
    const hasComponentAddPermission = hasPermission(userDetails, "Component Library", "Add");
    const hasComponentViewPermission = hasPermission(userDetails, "Component Library", "View");
    const hasBundleAddPermission = hasPermission(userDetails, "Bundles", "Add");
    const hasBundleViewPermission = hasPermission(userDetails, "Bundles", "View");
    const hasProductsSectionPermission = hasListingViewPermission || hasComponentAddPermission || hasComponentViewPermission || hasBundleAddPermission || hasBundleViewPermission;

    const hasInboundItemsViewPermission = hasPermission(userDetails, "Inbound", "View");
    const hasInstockItemsViewPermission = hasPermission(userDetails, "In-stock", "View");
    const hasInventorySectionPermission = hasInboundItemsViewPermission || hasInstockItemsViewPermission;

    const hasTasksViewPermission = hasPermission(userDetails, "Task Management", "View");
    const hasTaskCreatePermission = hasPermission(userDetails, "Task Management", "Add");
    const hasTaskSectionPermission = hasTaskCreatePermission || hasTasksViewPermission;

    const hasReportSectionPermission = hasTasksViewPermission;

    const hasAddUserPermission = hasPermission(userDetails, "User Management", "Add");
    const hasViewUserPermission = hasPermission(userDetails, "User Management", "View");
    const hasAccountSectionPermission = hasAddUserPermission || hasViewUserPermission;

    return (
        <CSidebar
            className="border-end"
            colorScheme="light"
            position="fixed"
            unfoldable={unfoldable}
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                dispatch({ type: 'set', sidebarShow: visible })
            }}
        >
            <CSidebarHeader className="border-bottom">
                <CSidebarBrand className='dropdown-item' as={NavLink} to="/">
                    <TbHexagonLetterJ size={24} className="mb-1 mx-1" />
                    <span className='pt-2 position-relative'>
                        JBL Prep Master
                        {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">
                                Center
                                <span className="visually-hidden">Prep Center Master Account</span>
                            </span>
                        )}
                    </span>
                </CSidebarBrand>
                <CCloseButton
                    className="d-lg-none"
                    dark
                    onClick={() => dispatch({ type: 'set', sidebarShow: false })}
                />
            </CSidebarHeader>
            <CSidebarNav>
                {hasProductsSectionPermission && (
                    <CNavTitle>Products</CNavTitle>
                )}

                {hasListingViewPermission && (
                    <CNavGroup
                        toggler={
                            <>
                                <LiaSitemapSolid size={20} className='me-2 text-info' /> Listings
                            </>
                        }
                    >
                        <CNavLink to="/listings" as={NavLink}>
                            <CiViewList size={20} className='me-2 text-info' /> View Listings
                        </CNavLink>
                    </CNavGroup>
                )}

                {(hasBundleAddPermission || hasBundleViewPermission) && (
                    <CNavGroup
                        toggler={
                            <>
                                <GrBundle size={20} className='me-2 text-danger' /> Bundles
                            </>
                        }
                    >
                        {hasBundleAddPermission && (
                            <CNavLink to="/bundle/add" as={NavLink}>
                                <MdAddBusiness size={20} className='me-2 text-danger' /> Add Bundle
                            </CNavLink>
                        )}

                        {hasBundleViewPermission && (
                            <CNavLink to="/bundles" as={NavLink}>
                                <CiViewList size={20} className='me-2 text-danger' /> View Bundles
                            </CNavLink>
                        )}
                    </CNavGroup>
                )}

                {(hasComponentAddPermission || hasComponentViewPermission) && (
                    <CNavGroup
                        toggler={
                            <>
                                <LuComponent size={20} className='me-2 text-warning' /> Components
                            </>
                        }
                    >
                        {hasComponentAddPermission && (
                            <CNavLink to="/add-component" as={NavLink}>
                                <MdAddBusiness size={20} className='me-2 text-warning' /> Add Component
                            </CNavLink>
                        )}

                        {hasComponentViewPermission && (
                            <CNavLink to="/components" as={NavLink}>
                                <CiViewList size={20} className='me-2 text-warning' /> View Components
                            </CNavLink>
                        )}
                    </CNavGroup>
                )}


                {hasInventorySectionPermission && (
                    <CNavTitle>Inventory</CNavTitle>
                )}

                {hasInboundItemsViewPermission && (
                    <CNavItem>
                        <CNavLink to="/inbound" as={NavLink}>
                            <BsTelephoneInbound size={20} className='me-2 text-warning' /> Inbound Items
                        </CNavLink>
                    </CNavItem>
                )}
                {hasInstockItemsViewPermission && (
                    <CNavItem>
                        <CNavLink to="/instock" as={NavLink}>
                            <MdOutlineInventory size={20} className='me-2 text-primary' /> Instock Items
                        </CNavLink>
                    </CNavItem>
                )}


                {hasTaskSectionPermission && (
                    <CNavTitle>Tasks</CNavTitle>
                )}
                {hasTaskCreatePermission && (
                    <CNavItem>
                        <CNavLink to="/task/add" as={NavLink}>
                            <LuListTodo size={20} className='me-2 text-warning' /> Create Task
                        </CNavLink>
                    </CNavItem>
                )}
                {hasTasksViewPermission && (
                    <>
                        <CNavItem>
                            <CNavLink to="/tasks" as={NavLink}>
                                <FaTasks size={20} className='me-2 text-success' /> View Tasks
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink to="/task-history" as={NavLink}>
                                <IoCheckmarkDoneCircleOutline size={20} className='me-2 text-primary' /> Task History
                            </CNavLink>
                        </CNavItem>
                    </>
                )}


                {hasReportSectionPermission && (
                    <CNavTitle>Reports</CNavTitle>
                )}
                {hasTasksViewPermission && (
                    <CNavItem>
                        <CNavLink to="/reports" as={NavLink}>
                            <RiFileExcel2Fill size={20} className='me-2 text-success' /> Manage Reports
                        </CNavLink>
                    </CNavItem>
                )}


                {hasAccountSectionPermission && userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                    <>
                        <CNavTitle>Account</CNavTitle>
                        <CNavGroup
                            toggler={
                                <>
                                    <FaUsers size={20} className='me-2 text-primary' /> Manage Clients
                                </>
                            }
                        >
                            {hasAddUserPermission && (
                                <CNavLink to="/users/add-user" as={NavLink}>
                                    <AiOutlineUserAdd size={20} className='me-2 text-warning' /> Add Client
                                </CNavLink>
                            )}
                            {hasViewUserPermission && (
                                <CNavLink to="/users/view-all" as={NavLink}>
                                    <FaUsersViewfinder size={20} className='me-2 text-success' /> View Clients
                                </CNavLink>
                            )}
                        </CNavGroup>
                    </>
                )}

                {hasAccountSectionPermission && userDetails.role !== USER_ROLES.PREP_CENTER_ADMIN && (
                    <CNavGroup
                        toggler={
                            <>
                                <FaUsers size={20} className='me-2 text-primary' /> Manage Users
                            </>
                        }
                    >
                        {hasAddUserPermission && (
                            <CNavLink to="/users/add-user" as={NavLink}>
                                <AiOutlineUserAdd size={20} className='me-2 text-warning' /> Add User
                            </CNavLink>
                        )}
                        {hasViewUserPermission && (
                            <CNavLink to="/users/view-all" as={NavLink}>
                                <FaUsersViewfinder size={20} className='me-2 text-success' /> View Users
                            </CNavLink>
                        )}
                    </CNavGroup>
                )}

            </CSidebarNav>
        </CSidebar>
    )
}

export default AppSidebar;
