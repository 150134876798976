import { useEffect, useState } from "react";
import { CURRENCY_TO_SYMBOL_MAP, serverEndpoint } from "../../util/config";
import axios from "axios";
import { ThreeDot } from "react-loading-indicators";
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { CRow, CCol, CWidgetStatsA, CWidgetStatsD, } from '@coreui/react';
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { IoInformationCircleOutline } from "react-icons/io5";
import { CChartBar, CChartLine } from '@coreui/react-chartjs'
import Tooltip from '@mui/material/Tooltip';
import { getStyle } from '@coreui/utils'
import { MdOutlineLocalShipping } from "react-icons/md";
import { LiaCartArrowDownSolid } from "react-icons/lia";
import { RiShipLine } from "react-icons/ri";
import 'rsuite/DateRangePicker/styles/index.css';
import DateRangePicker from 'rsuite/DateRangePicker';
import subDays from 'date-fns/subDays';
import { format, isValid } from 'date-fns';
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";
import { USER_ROLES } from "../../util/constants";
import Select from 'react-select';

function Analytics() {
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);

    const userDetails = useSelector((state) => state.userDetails);
    const currencySymbol = CURRENCY_TO_SYMBOL_MAP[userDetails.accountCurrency] || '£';
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterDates, setFilterDates] = useState([last7Days, today]);
    const [errors, setErrors] = useState({});
    const [selectedPrepMasterAccount, setSelectedPrepMasterAccount] = useState(null);
    const [prepMasterAccountsLoading, setPrepMasterAccountsLoading] = useState(true);
    const [prepMasterAccounts, setPrepMasterAccounts] = useState([]);

    const handlePrepMasterAccountChange = async (option) => {
        setSelectedPrepMasterAccount(option);
    };

    const getPrepMasterAccounts = async () => {
        try {
            const response = await axios.post(`${serverEndpoint}/user/get-users`, {
                master_account_id: userDetails.masterAccountId
            }, { withCredentials: true });
            const accounts = response?.data?.users?.map((account) => ({
                value: account.email,
                label: account.email,
            }));
            setPrepMasterAccounts(accounts);
        } catch (error) {
            console.log(error);
            setErrors({ message: "Unable to fetch Prep Master Accounts for Filter" });
        } finally {
            setPrepMasterAccountsLoading(false);
        }
    };

    useEffect(() => {
        if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
            getPrepMasterAccounts();
        } else {
            setPrepMasterAccountsLoading(false);
        }
    }, []);

    const handleChange = async (value) => {
        const [startDate, endDate] = value;
        if (startDate && endDate && isValid(startDate) && isValid(endDate)) {
            if (startDate <= endDate) {
                setFilterDates(value);
            } else {
                setFilterDates([last7Days, today]);
            }
        } else {
            setFilterDates([last7Days, today]);
        }
    };

    const getAnalytics = async () => {
        try {
            setLoading(true);
            const todayDate = format(filterDates[1], 'yyyy-MM-dd');
            const todayMinusXDaysDate = format(filterDates[0], 'yyyy-MM-dd');
            let params = {};

            if (userDetails.role === USER_ROLES.PREP_CENTER_ADMIN) {
                if (selectedPrepMasterAccount !== null) {
                    params = {
                        emailId: selectedPrepMasterAccount.value
                    };
                } else {
                    params = {
                        emailId: userDetails.username,
                        prep_center_id: userDetails.masterAccountId
                    };
                }
            }
            const response = await axios.get(`${serverEndpoint}/analytics/get/${todayMinusXDaysDate}/${todayDate}`, {
                params: params,
                withCredentials: true
            });
            setAnalyticsData(response.data);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong!' })
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnalytics();
    }, [filterDates, selectedPrepMasterAccount]);

    const predefinedRanges = [
        {
            label: 'Last 7 days',
            value: [subDays(new Date(), 6), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 14 days',
            value: [subDays(new Date(), 13), new Date()],
            placement: 'left'
        },
        {
            label: 'Last 30 days',
            value: [subDays(new Date(), 29), new Date()],
            placement: 'left'
        },
    ];

    if (errors.fatal) {
        return <ErrorComponent />;
    }

    return (
        <div className="container bg-white p-4 rounded border">
            <div className="row align-items-center">
                {errors.message && (
                    <AutoDismissibleAlert type="danger" message={errors.message} />
                )}

                {/* Left Section: Title and Subtitle */}
                <div className="col mb-2 mb-md-0">
                    <h2 className="mb-0">Dashboard</h2>
                </div>

                {/* Right Section: Filter and Select */}
                <div className="col-auto d-flex flex-wrap gap-2 align-items-center">
                    <div className="flex-grow-1" style={{ minWidth: '250px' }}>
                        <DateRangePicker
                            format="dd MMM, yyyy"
                            character=" to "
                            label="Filter: "
                            ranges={predefinedRanges}
                            value={filterDates}
                            onChange={handleChange}
                            cleanable={false}
                            className="w-100"
                            showOneCalendar
                        />
                    </div>
                    {userDetails.role === USER_ROLES.PREP_CENTER_ADMIN && (
                        <div className="flex-grow-1" style={{ minWidth: '350px' }}>
                            <Select
                                options={prepMasterAccounts}
                                isClearable={true}
                                placeholder="Filter: Prep Master Account"
                                isLoading={prepMasterAccountsLoading}
                                isDisabled={prepMasterAccountsLoading}
                                value={selectedPrepMasterAccount}
                                onChange={handlePrepMasterAccountChange}
                                className="w-100"
                            />
                        </div>
                    )}
                </div>


            </div>

            <hr className="text-muted" />

            {userDetails.role !== USER_ROLES.PREP_CENTER_ADMIN && (
                <>
                    <CRow className="mb-4" xs={{ gutter: 4 }}>
                        <CCol sm={6} xl={4} xxl={3}>
                            <CWidgetStatsA
                                color="info"
                                value={
                                    <>
                                        {loading ? (
                                            <ThreeDot size="small" color="#fff" />
                                        ) : (
                                            <>{currencySymbol}{analyticsData.summary.sales}</>
                                        )}{' '}
                                        <span className="fs-6 fw-normal">
                                            ({loading ? (
                                                <ThreeDot size="small" color="#fff" />
                                            ) : (
                                                <>{currencySymbol}{analyticsData.summary.sales_avg}</>
                                            )} Avg)
                                        </span>
                                    </>
                                }
                                title="Sales"
                                action={
                                    <Tooltip title="Represents the total sales value of the products you’ve sent out during the selected period. This does not reflect actual sales on Amazon but rather the potential sales value based on the stock shipped out." arrow placement="top" className="mt-1">
                                        <div style={{ cursor: 'pointer' }}><IoInformationCircleOutline size={24} /></div>
                                    </Tooltip>
                                }
                                chart={
                                    <CChartBar
                                        className="mt-3 mx-3"
                                        style={{ height: '30px' }}
                                        data={{
                                            labels: [

                                            ],
                                            datasets: [],
                                        }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    border: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                        drawBorder: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                }
                            />
                        </CCol>
                        <CCol sm={6} xl={4} xxl={3}>
                            <CWidgetStatsA
                                color="primary"
                                value={
                                    <>
                                        {loading ? (
                                            <ThreeDot size="small" color="#fff" />
                                        ) : (
                                            <>{currencySymbol}{analyticsData.summary.profit}</>
                                        )}{' '}
                                        <span className="fs-6 fw-normal">
                                            ({loading ? (
                                                <ThreeDot size="small" color="#fff" />
                                            ) : (
                                                <>{currencySymbol}{analyticsData.summary.profit_avg}</>
                                            )} Avg)
                                        </span>
                                    </>
                                }
                                title="Profit"
                                action={
                                    <Tooltip title="Shows the estimated profit from the products you’ve sent out during the selected period. This figure is based on the cost of goods (COG) you’ve entered and the sales value. It does not reflect actual Amazon sales profit but is an estimation based on the stock sent." arrow placement="top" className="mt-1">
                                        <div style={{ cursor: 'pointer' }}><IoInformationCircleOutline size={24} /></div>
                                    </Tooltip>
                                }
                                chart={
                                    <CChartBar
                                        className="mt-3 mx-3"
                                        style={{ height: '30px' }}
                                        data={{
                                            labels: [

                                            ],
                                            datasets: [],
                                        }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    border: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                        drawBorder: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                }
                            />
                        </CCol>
                        <CCol sm={6} xl={4} xxl={3}>
                            <CWidgetStatsA
                                color="warning"
                                value={
                                    <>
                                        {loading ? (
                                            <ThreeDot size="small" color="#fff" />
                                        ) : (
                                            <>{currencySymbol}{analyticsData.stats.inboundItemsTotalCog}</>
                                        )}{' '}
                                    </>
                                }
                                title="Inbound COG"
                                action={
                                    <Tooltip title="Displays the total cost of goods for items currently marked as inbound to your warehouse, home, or prep centre. This allows you to track the financial value of your incoming stock." arrow placement="top" className="mt-1">
                                        <div style={{ cursor: 'pointer' }}><IoInformationCircleOutline size={24} /></div>
                                    </Tooltip>
                                }
                                chart={
                                    <CChartBar
                                        className="mt-3 mx-3"
                                        style={{ height: '30px' }}
                                        data={{
                                            labels: [

                                            ],
                                            datasets: [],
                                        }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    border: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                        drawBorder: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                }
                            />
                        </CCol>
                        <CCol sm={6} xl={4} xxl={3}>
                            <CWidgetStatsA
                                color="danger"
                                value={
                                    <>
                                        {loading ? (
                                            <ThreeDot size="small" color="#fff" />
                                        ) : (
                                            <>{currencySymbol}{analyticsData.stats.warehouseItemsTotalCog}</>
                                        )}{' '}
                                    </>
                                }
                                title="Warehouse COG"
                                action={
                                    <Tooltip title="Reflects the total cost of goods for stock currently available at your warehouse or stored at your location. This helps provide an accurate snapshot of your inventory's financial value." arrow placement="top" className="mt-1">
                                        <div style={{ cursor: 'pointer' }}><IoInformationCircleOutline size={24} /></div>
                                    </Tooltip>
                                }
                                chart={
                                    <CChartBar
                                        className="mt-3 mx-3"
                                        style={{ height: '30px' }}
                                        data={{
                                            labels: [

                                            ],
                                            datasets: [],
                                        }}
                                        options={{
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: {
                                                    display: false,
                                                },
                                            },
                                            scales: {
                                                x: {
                                                    grid: {
                                                        display: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                                y: {
                                                    border: {
                                                        display: false,
                                                    },
                                                    grid: {
                                                        display: false,
                                                        drawBorder: false,
                                                        drawTicks: false,
                                                    },
                                                    ticks: {
                                                        display: false,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                }
                            />
                        </CCol>
                    </CRow>

                    <CCard className="mb-4">
                        <CCardBody>
                            <CRow>
                                <CCol sm={5}>
                                    <h4 id="traffic" className="card-title mb-0">
                                        Sales & Profit Trend
                                        <span className="badge text-secondary">
                                            <Tooltip title="This graph shows daily sales and profit trends. Hover over the points to view specific values for each day." arrow placement="top" className="mt-1">
                                                <div style={{ cursor: 'pointer' }}><IoInformationCircleOutline size={22} className="mb-1" /></div>
                                            </Tooltip>
                                        </span>
                                    </h4>
                                    <div className="small text-body-secondary">
                                        {new Date(filterDates[0]).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })} {' '}
                                        to {' '}
                                        {new Date(filterDates[1]).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}
                                    </div>

                                </CCol>
                            </CRow>
                            <CChartLine
                                style={{ height: '300px', marginTop: '40px' }}
                                customTooltips={false}
                                data={{
                                    labels: loading
                                        ? [] // Show empty labels while loading
                                        : analyticsData?.profit_sales_by_day?.map((item) => {
                                            const date = new Date(item.date);
                                            const options = { day: '2-digit', month: 'short', year: 'numeric' };
                                            return date.toLocaleDateString('en-US', options);
                                        }),
                                    datasets: [
                                        {
                                            label: 'Sales',
                                            backgroundColor: getStyle('--cui-info'),
                                            borderColor: getStyle('--cui-info'),
                                            pointHoverBackgroundColor: getStyle('--cui-info'),
                                            pointHoverBorderWidth: 0,
                                            borderWidth: 2,
                                            data: loading ? [] : analyticsData?.profit_sales_by_day?.map(item => item.sales),
                                            fill: false,
                                        },
                                        {
                                            label: 'Profit',
                                            backgroundColor: getStyle('--cui-success'),
                                            borderColor: getStyle('--cui-success'),
                                            pointHoverBackgroundColor: getStyle('--cui-success'),
                                            pointHoverBorderWidth: 0,
                                            borderWidth: 2,
                                            data: loading ? [] : analyticsData?.profit_sales_by_day?.map(item => item.profit),
                                        },
                                    ],
                                }}
                                options={{
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: true,
                                        },
                                    },

                                    scales: {
                                        x: {
                                            grid: {
                                                color: getStyle('--cui-border-color-translucent'),
                                            },
                                            ticks: {
                                                color: getStyle('--cui-body-color'),
                                            },
                                        },
                                        y: {
                                            beginAtZero: true,
                                            border: {
                                                color: getStyle('--cui-border-color-translucent'),
                                            },
                                            grid: {
                                                color: getStyle('--cui-border-color-translucent'),
                                            },
                                            // Dynamically calculate max value
                                            max: loading
                                                ? 250
                                                : Math.max(
                                                    ...analyticsData?.profit_sales_by_day?.map(item => Math.max(item.sales, item.profit)),
                                                    0 // Default to 0 if there's no data
                                                ) * 1.1, // Adding a 10% buffer
                                            ticks: {
                                                color: getStyle('--cui-body-color'),
                                                maxTicksLimit: 5,
                                                stepSize: (loading
                                                    ? 250
                                                    : Math.max(
                                                        ...analyticsData?.profit_sales_by_day?.map(item => Math.max(item.sales, item.profit)),
                                                        0
                                                    ) * 1.1) / 5, // Calculate step size based on dynamic max
                                                callback: function (value) {
                                                    return `${currencySymbol}${value.toFixed(2)}`; // Change `$` to your currency symbol (e.g., ₹)
                                                },
                                            },
                                        },
                                    },
                                    elements: {
                                        line: {
                                            tension: 0.4,
                                        },
                                        point: {
                                            radius: 0,
                                            hitRadius: 10,
                                            hoverRadius: 4,
                                            hoverBorderWidth: 3,
                                        },
                                    },
                                }}
                            />

                        </CCardBody>
                    </CCard>
                </>
            )}

            <CRow className="mb-4" xs={{ gutter: 4 }}>
                <CCol sm={6} xl={4} xxl={4}>
                    <CWidgetStatsD
                        icon={
                            <>
                                <RiShipLine size={64} height={52} className="my-4 text-white" />
                            </>
                        }
                        style={{ '--bs-card-cap-bg': '#3b5998' }}
                        values={loading ? [
                            {
                                title: 'Items Inbound',
                                value: (
                                    <>
                                        <span>-</span>
                                        <Tooltip title="Loading data..." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Units Inbound',
                                value: (
                                    <>
                                        <span>-</span>
                                        <Tooltip title="Loading data..." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                        ] : [
                            {
                                title: 'Items Inbound',
                                value: (
                                    <>
                                        <span>{analyticsData.stats.inboundItemsCount}</span>
                                        <Tooltip title="Displays the number of unique items currently marked as inbound to your designated location." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Units Inbound',
                                value: (
                                    <>
                                        <span>{analyticsData.stats.inboundUnitsCount}</span>
                                        <Tooltip title="Reflects the total number of units for all inbound items. This helps track the volume of stock on its way to your location." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                        ]}
                    />
                </CCol>

                <CCol sm={6} xl={4} xxl={4}>
                    <CWidgetStatsD
                        icon={
                            <>
                                <LiaCartArrowDownSolid size={64} height={52} className="my-4 text-white" />
                            </>
                        }
                        values={loading ? [
                            {
                                title: 'Items Arrived',
                                value: (
                                    <>
                                        <span>-</span>
                                        <Tooltip title="Loading data..." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Units Arrived',
                                value: (
                                    <>
                                        <span>-</span>
                                        <Tooltip title="Loading data..." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                        ] : [
                            {
                                title: 'Items Arrived',
                                value: (
                                    <>
                                        <span>{analyticsData.summary.itemsArrived}</span>
                                        <Tooltip title="Displays the total number of unique items that have arrived at your designated location during the selected period." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Units Arrived',
                                value: (
                                    <>
                                        <span>{analyticsData.summary.unitsArrived}</span>
                                        <Tooltip title="Shows the total number of units from shipments that have arrived at your warehouse, home, or prep centre during the selected period." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                        ]}
                        style={{
                            '--bs-card-cap-bg': '#20ac6b',
                        }}
                    />
                </CCol>

                <CCol sm={6} xl={4} xxl={4}>
                    <CWidgetStatsD
                        icon={
                            <>
                                <MdOutlineLocalShipping size={64} height={52} className="my-4 text-white" />
                            </>
                        }
                        values={loading ? [
                            {
                                title: 'Items Shipped',
                                value: (
                                    <>
                                        <span>-</span>
                                        <Tooltip title="Loading data..." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Units Shipped',
                                value: (
                                    <>
                                        <span>-</span>
                                        <Tooltip title="Loading data..." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                        ] : [
                            {
                                title: 'Items Shipped',
                                value: (
                                    <>
                                        <span>{analyticsData.summary.itemsShippedOut}</span>
                                        <Tooltip title="Indicates the total number of unique items sent out during the selected period. This helps you track the volume of shipments." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                title: 'Units Shipped',
                                value: (
                                    <>
                                        <span>{analyticsData.summary.unitsShippedOut}</span>
                                        <Tooltip title="Represents the total quantity of all units sent out during the selected period, offering a more detailed insight into stock movement." arrow placement="top">
                                            <span style={{ display: 'inline-block', cursor: 'pointer', verticalAlign: 'super', fontSize: '0.8em' }}>
                                                <IoInformationCircleOutline size={15} className="ms-1 text-secondary" />
                                            </span>
                                        </Tooltip>
                                    </>
                                ),
                            },
                        ]}
                        style={{
                            '--bs-card-cap-bg': '#0d6efd',
                        }}
                    />
                </CCol>
            </CRow>

            <CRow>
                <CCol xs={{ cols: 1, gutter: 4 }}
                    sm={{ cols: 2 }}
                    lg={{ cols: 4 }}
                    xl={{ cols: 5 }} className="mb-4">
                    <CCard>
                        <CCardHeader>
                            Your Account at a Glance
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol>
                                    <div className="border-start border-start-4 border-start-info py-1 px-3">
                                        <div className="text-body-secondary text-truncate small">Listings</div>
                                        <div className="fs-5 fw-semibold">
                                            {loading ? (
                                                <ThreeDot size="small" color="#3399ff" />
                                            ) : (
                                                <>
                                                    {analyticsData.stats.listingsCount}
                                                    <Tooltip
                                                        title="Shows the total number of active product listings you’ve added to the system. Listings represent individual ASINs or SKUs you’re managing through the software."
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                cursor: 'pointer',
                                                                verticalAlign: 'super',
                                                                fontSize: '0.8em',
                                                                marginLeft: '4px'
                                                            }}
                                                        >
                                                            <IoInformationCircleOutline size={15} className="text-secondary" />
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </CCol>
                                <CCol>
                                    <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                                        <div className="text-body-secondary text-truncate small">
                                            Bundles
                                        </div>
                                        <div className="fs-5 fw-semibold">
                                            {loading ? (
                                                <ThreeDot size="small" color="#e55353" />
                                            ) : (
                                                <>
                                                    {analyticsData.stats.bundlesCount}
                                                    <Tooltip
                                                        title="Reflects the total number of bundle configurations you’ve created within the system. These are multi-product packs or combinations that you’ve set up."
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                cursor: 'pointer',
                                                                verticalAlign: 'super',
                                                                fontSize: '0.8em',
                                                                marginLeft: '4px'
                                                            }}
                                                        >
                                                            <IoInformationCircleOutline size={15} className="text-secondary" />
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </CCol>
                                <CCol>
                                    <div className="border-start border-start-4 border-start-warning py-1 px-3 mb-3">
                                        <div className="text-body-secondary text-truncate small">
                                            Components
                                        </div>
                                        <div className="fs-5 fw-semibold">
                                            {loading ? (
                                                <ThreeDot size="small" color="#f9b115" />
                                            ) : (
                                                <>
                                                    {analyticsData.stats.componentsCount}
                                                    <Tooltip
                                                        title="Displays the total number of individual components you’ve added to the system. Components are smaller parts of bundles."
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                cursor: 'pointer',
                                                                verticalAlign: 'super',
                                                                fontSize: '0.8em',
                                                                marginLeft: '4px'
                                                            }}
                                                        >
                                                            <IoInformationCircleOutline size={15} className="text-secondary" />
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </CCol>
                                <CCol>
                                    <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                                        <div className="text-body-secondary text-truncate small">
                                            Tasks
                                        </div>
                                        <div className="fs-5 fw-semibold">
                                            {loading ? (
                                                <ThreeDot size="small" color="#1b9e3e" />
                                            ) : (
                                                <>
                                                    {analyticsData.stats.tasksCount}
                                                    <Tooltip
                                                        title="Represents the total number of tasks currently on your to-do list, helping you manage and track progress efficiently."
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                cursor: 'pointer',
                                                                verticalAlign: 'super',
                                                                fontSize: '0.8em',
                                                                marginLeft: '4px'
                                                            }}
                                                        >
                                                            <IoInformationCircleOutline size={15} className="text-secondary" />
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </CCol>
                                <CCol>
                                    <div className="border-start border-start-4 border-start-primary py-1 px-3 mb-3">
                                        <div className="text-body-secondary text-truncate small">
                                            Users
                                        </div>
                                        <div className="fs-5 fw-semibold">
                                            {loading ? (
                                                <ThreeDot size="small" color="#1a77fd" />
                                            ) : (
                                                <>
                                                    {analyticsData.stats.usersCount}
                                                    <Tooltip
                                                        title="Shows the number of active users in your account. Users can include team members, such as purchasing VAs or staff, with specific permissions. This feature is only available to users on the Advanced Plan."
                                                        arrow
                                                        placement="top"
                                                    >
                                                        <span
                                                            style={{
                                                                display: 'inline-block',
                                                                cursor: 'pointer',
                                                                verticalAlign: 'super',
                                                                fontSize: '0.8em',
                                                                marginLeft: '4px'
                                                            }}
                                                        >
                                                            <IoInformationCircleOutline size={15} className="text-secondary" />
                                                        </span>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>
        </div>
    );
}

export default Analytics;