import { useEffect, useState } from "react";
import axios from 'axios';
import { serverEndpoint } from "../../../util/config";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import { managerPermissions, viewerPermissions, staffPermissions } from "../../../util/permissionTemplates";
import Spinner from "../../../components/Spinner";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import AutoDismissibleAlert from "../../../components/AutoDismissibleAlert";

function AddUser({ userDetails }) {
    const [pageLoading, setPageLoading] = useState(true);
    const [isAddAllowed, setIsAddAllowed] = useState(true);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        role: 'Select',
        permissions: {}, // Set permissions to an object initially
    });

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const permissionsTemplate = {
        "User Management": ["Add", "Edit", "Delete", "View"],
        "Inbound": ["Add", "Edit", "Delete", "View"],
        "In-stock": ["Add", "Edit", "Delete", "View"],
        "Component Library": ["Add", "Edit", "Delete", "View"],
        "Amazon Listing": ["Add", "Edit", "Delete", "View"],
        "Bundles": ["Add", "Edit", "Delete", "View"],
        "Task Management": ["Add", "Edit", "Delete", "View"],
        "Reporting": ["Add", "Edit", "Delete", "View"]
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        const phoneRegex = /^\+[1-9]{1}[0-9]{7,14}$/;
        if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = 'Please provide a valid Phone';
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please provide a valid Email';
            isValid = false;
        }

        if (formData.role === 'Select') {
            newErrors.role = 'Please select a valid role for the user';
            isValid = false;
        }

        if (formData.role === 'Custom' && Object.keys(formData.permissions).length === 0) {
            newErrors.role = 'Please add some permissions for the user';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, [name]: value };

            if (name === "role") {
                if (value === 'manager') {
                    updatedFormData.permissions = { ...managerPermissions };
                } else if (value === 'viewer') {
                    updatedFormData.permissions = { ...viewerPermissions };
                } else if (value === 'staff') {
                    updatedFormData.permissions = { ...staffPermissions };
                } else if (value === 'Custom') {
                    updatedFormData.permissions = {};
                }
            }

            return updatedFormData;
        });
    };

    const handleTogglePermission = (section, action) => {
        const newPermissions = { ...formData.permissions };
        if (!newPermissions[section]) {
            newPermissions[section] = [];
        }

        if (newPermissions[section].includes(action)) {
            newPermissions[section] = newPermissions[section].filter(perm => perm !== action);
        } else {
            newPermissions[section].push(action);
        }

        setFormData({
            ...formData,
            permissions: newPermissions
        });
    };

    const handleSubmit = async (e) => {
        setMessage(null);
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/user/add-user`, {
                username: formData.email,
                role: formData.role,
                permissions: formData.permissions,
                phone: formData.phone,
                master_account_id: userDetails.masterAccountId,
                business_name: userDetails.businessName
            }, { withCredentials: true })
                .then(response => {
                    setMessage('User created! A temporary password has been sent to their email.');
                    setFormData({
                        email: '',
                        phone: '',
                        role: 'Select',
                        permissions: {},
                    });
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    if (error.response?.data?.error) {
                        setErrors({ message: error.response.data.error });
                    } else if (error.response?.data?.message) {
                        setErrors({ message: error.response.data.message });
                    } else {
                        setErrors({ message: "Something went wrong, please try again!" });
                    }
                    setLoading(false);
                });
        }
    };

    const getPermissionsForRole = () => {
        switch (formData.role) {
            case 'manager':
                return managerPermissions;
            case 'viewer':
                return viewerPermissions;
            case 'staff':
                return staffPermissions;
            default:
                return {};
        }
    };

    const currentPermissions = getPermissionsForRole();

    const checkUserCount = async () => {
        try {
            const response = await axios.get(`${serverEndpoint}/user/get-user-count`, {
                params: {
                    master_account_id: userDetails.masterAccountId
                },
                withCredentials: true
            });
            if (response.data.user_count >= 9) {
                setIsAddAllowed(false);
            }
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again' });
        } finally {
            setPageLoading(false);
        }
    };

    useEffect(() => {
        checkUserCount();
    }, []);

    if (pageLoading) {
        return <Spinner />;
    }

    if (!isAddAllowed) {
        return (
            <Container className="my-2 bg-white border rounded p-3">
                <Row className="justify-content-center">
                    <Col md={12}>
                        <h2 className="">Limit Reached!</h2>
                        <hr />
                        <p className="text-muted">
                            You have reached the default limit of 10 logins per account. To request additional logins, please {' '}
                            <Link to="https://www.jblsoftware.com/extras/contact-us" target="_blank">
                                Contact Us
                            </Link>.
                        </p>

                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>Add User</h2>
                    <p className="text-secondary mb-2">Add a user to manage your Prep Master Account</p>

                    {errors.message && (
                        <AutoDismissibleAlert type="danger" message={errors.message} />
                    )}

                    {message && (
                        <AutoDismissibleAlert type="success" message={message} />
                    )}

                    <form onSubmit={handleSubmit} className="p-4 rounded border bg-white">
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="email">
                                Email
                            </label>
                            <input id="email" type="text" className={errors.email ? 'form-control is-invalid' : 'form-control'}
                                name="email" placeholder="name@host.com" onChange={handleChange}
                                value={formData.email} autoFocus
                            />
                            <div className="invalid-feedback">Please enter a valid email.</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="phone">
                                Phone
                            </label>
                            <input id="phone" type="text" name="phone" placeholder="+440123456789"
                                className={errors.phone ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleChange} value={formData.phone}
                            />
                            <div className="invalid-feedback">Phone number must start with '+' followed by the country code and only contain digits. Remove any other characters (e.g., spaces, dashes). Format example: +14325551212.</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="role">
                                Role
                            </label>
                            <select className={errors.role ? 'form-control is-invalid' : 'form-control'}
                                name="role" value={formData.role} onChange={handleChange}>
                                <option value="Select">Select</option>
                                <option value="manager">Manager</option>
                                <option value="staff">Staff</option>
                                <option value="viewer">Viewer</option>
                                <option value="Custom">Custom</option>
                            </select>
                            <div className="invalid-feedback">{errors.role}</div>
                        </div>

                        {formData.role === 'Custom' && (
                            <div className="mb-3">
                                <label className="mb-2 text-muted">Permissions</label>
                                {Object.keys(permissionsTemplate).map((section) => (
                                    <div key={section} className="border p-2 m-1 rounded-2">
                                        <h6>{section}</h6>
                                        <div className="d-flex flex-wrap">
                                            {permissionsTemplate[section].map((action) => (
                                                <div className="form-check form-check-inline" key={action}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`${section}-${action}`}
                                                        checked={formData.permissions[section]?.includes(action)}
                                                        onChange={() => handleTogglePermission(section, action)}
                                                    />
                                                    <label className="form-check-label" htmlFor={`${section}-${action}`}>
                                                        {action}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        )}

                        {formData.role !== 'Custom' && (
                            <div className="mb-3">
                                <label className="mb-2 text-muted">Permissions</label>
                                {Object.keys(permissionsTemplate).map((section) => (
                                    <div key={section} className="border p-2 m-1 rounded-2">
                                        <h6>{section}</h6>
                                        <div className="d-flex flex-wrap">
                                            {permissionsTemplate[section].map((action) => (
                                                <div className="form-check form-check-inline" key={action}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`${section}-${action}`}
                                                        checked={currentPermissions[section]?.includes(action)}
                                                        disabled
                                                    />
                                                    <label className="form-check-label" htmlFor={`${section}-${action}`}>
                                                        {action}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className="align-items-center d-flex">
                            {loading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!loading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Add
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddUser;
