import { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import VerifyEmail from "./VerifyEmail";
import { TbHexagonLetterJ } from "react-icons/tb";
import AutoDismissibleAlert from "../../components/AutoDismissibleAlert";

function Signup() {
    const [loading, setLoading] = useState(false);
    const [verifyEmailDetails, setVerifyEmailDetails] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        phone: '',
        business_name: '',
        referral_code: '',
        termsAccepted: false
    });

    const [errors, setErrors] = useState({
        passwordErrors: [],
        business_name: '',
        password: '',
        email: '',
        phone: '',
        message: '',
        referral_code: '',
        termsAccepted: ''
    });

    const validatePassword = (password) => {
        const requirements = [
            { regex: /[a-z]/, message: "Must contain at least one lowercase letter" },
            { regex: /[A-Z]/, message: "Must contain at least one uppercase letter" },
            { regex: /\d/, message: "Must contain at least one number" },
            { regex: /[\s!@#$%^&*(),.?":{}|<>]/, message: "Must contain at least one special character or space" },
            { regex: /^.{8,}$/, message: "Must be at least 8 characters long" },
            { regex: /^(?!.*\s{2,}).*$/, message: "Must not contain consecutive spaces" },
        ];

        return requirements.filter((req) => !req.regex.test(password)).map((req) => req.message);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {
            passwordErrors: validatePassword(formData.password),
            business_name: "",
            email: "",
            phone: "",
            message: "",
            referral_code: "",
            termsAccepted: ""
        };

        if (formData.business_name.length < 3) {
            newErrors.business_name = 'Business Name must be at least 3 characters long.';
            isValid = false;
        }

        const phoneRegex = /^\+[1-9]{1}[0-9]{7,14}$/;
        if (!phoneRegex.test(formData.phone)) {
            newErrors.phone = "Phone number must start with '+' followed by the country code and only contain digits. Remove any other characters (e.g., spaces, dashes). Format example: +14325551212.";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please provide a valid Email';
            isValid = false;
        }

        if (newErrors.passwordErrors.length > 0) {
            isValid = false;
        }

        if (!formData.termsAccepted) {
            newErrors.termsAccepted = "You must agree to the terms and conditions to register.";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === "password") {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordErrors: validatePassword(value || ""), // Handle undefined
            }));
        }
    };

    const handleCheckBoxChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            termsAccepted: !prevData.termsAccepted // Toggle termsAccepted value in formData
        }))

    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);

            if (formData.referral_code && formData.referral_code.length > 0) {
                try {
                    const response = await axios.get(`${serverEndpoint}/referral/get-referrer/${formData.referral_code}`, { withCredentials: true });
                    if (!response.data.referrer) {
                        setErrors((prevErrors) => ({
                            ...prevErrors,
                            referral_code: 'The referral code you provided is not valid',
                        }));
                        setLoading(false);
                        return;
                    }
                } catch (error) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        message: 'Something went wrong while validating the referral code, please try again.',
                    }));
                    setLoading(false);
                    return;
                }
            } else {
                // Clear any previous referral code error if referral code is not provided
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    referral_code: '',
                }));
            }

            try {
                const response = await axios.post(
                    `${serverEndpoint}/auth/signup`,
                    {
                        email: formData.email,
                        password: formData.password,
                        business_name: formData.business_name,
                        phone: formData.phone,
                        referral_code: formData.referral_code || null, // Send null if empty
                    },
                    { withCredentials: true }
                );

                setVerifyEmailDetails({
                    email: formData.email,
                    businessName: formData.business_name,
                    phone: formData.phone,
                    password: formData.password,
                });
            } catch (error) {
                if (
                    error.response &&
                    error.response.data &&
                    error.response.data.error === 'User already exists'
                ) {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        message: 'An account already exists with the provided email.',
                    }));
                } else {
                    setErrors((prevErrors) => ({
                        ...prevErrors,
                        message: 'Something went wrong during signup, please try again.',
                    }));
                }
            } finally {
                setLoading(false);
            }
        }
    };

    if (verifyEmailDetails) {
        return <VerifyEmail userDetails={verifyEmailDetails} />
    }

    return (
        <section className="h-100">
            <div className="container h-100">
                <div className="row justify-content-sm-center h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                        <div className="card shadow-lg">
                            <div className="text-center mt-2">
                                <TbHexagonLetterJ size={90} className="text-primary" />
                            </div>
                            <div className="card-body p-4">
                                <h1 className="fs-4 card-title fw-bold mb-4">Sign up with a new account</h1>
                                {errors.message && (
                                    <AutoDismissibleAlert type="danger" message={errors.message} />
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="business_name">
                                            Business Name
                                        </label>
                                        <input id="business_name" type="text" className={errors.business_name ? 'form-control is-invalid' : 'form-control'}
                                            name="business_name" autoFocus onChange={handleChange} value={formData.business_name}
                                        />
                                        <div className="invalid-feedback">{errors.business_name}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="email">
                                            Email
                                        </label>
                                        <input id="email" type="text" className={errors.email ? 'form-control is-invalid' : 'form-control'}
                                            name="email" placeholder="name@host.com" onChange={handleChange}
                                            value={formData.email}
                                        />
                                        <div className="invalid-feedback">{errors.email}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="phone">
                                            Phone
                                        </label>
                                        <input id="phone" type="text" name="phone" placeholder="+440123456789"
                                            className={errors.phone ? 'form-control is-invalid' : 'form-control'}
                                            onChange={handleChange} value={formData.phone}
                                        />
                                        <div className="invalid-feedback">{errors.phone}</div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="password">
                                            Password
                                        </label>
                                        <input id="password" type="password" className={errors.password ? 'form-control is-invalid' : 'form-control'}
                                            name="password" onChange={handleChange} value={formData.password}
                                        />
                                        {errors.passwordErrors.length > 0 && (
                                            <ul className="text-danger small">
                                                {errors.passwordErrors.map((err, index) => (
                                                    <li key={index}>{err}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="mb-3">
                                        <label className="mb-2 text-muted" htmlFor="referral_code">
                                            Referral Code
                                        </label>
                                        <input id="referral_code" type="text" className={errors.referral_code ? 'form-control is-invalid' : 'form-control'}
                                            name="referral_code" onChange={handleChange} value={formData.referral_code}
                                        />
                                        <div className="invalid-feedback">{errors.referral_code}</div>
                                    </div>
                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <input type="checkbox" id="termsAccepted" className={errors.termsAccepted ? 'form-check-input me-2 is-invalid' : 'form-check-input me-2'} checked={formData.termsAccepted} onChange={handleCheckBoxChange} />
                                            <label htmlFor="termsAccepted" className="ms-2">
                                                I agree to the <Link to="https://www.jblsoftware.com/all-terms-and-conditions/jbl-prep-master" target="_blank">terms and conditions</Link>
                                            </label>
                                        </div>
                                        <div className="invalid-feedback d-flex">{errors.termsAccepted}</div>
                                    </div>
                                    <div className="align-items-center d-flex">
                                        {loading && (
                                            <button className="btn btn-primary ms-auto" type="button" disabled="">
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        )}

                                        {!loading && (
                                            <button className="btn btn-primary ms-auto" type="submit" disabled={!formData.termsAccepted}>
                                                Register
                                            </button>
                                        )}
                                    </div>
                                </form>
                            </div>
                            <div className="card-footer py-3 border-0">
                                <div className="text-center">
                                    Already have an account?{" "}
                                    <Link to="/signin" className="text-dark">
                                        Sign in
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Signup;