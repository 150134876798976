import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ErrorComponent() {
    return (
        <section className="bg-white p-4 rounded border">
            <Container>
                <Row className="px-3 px-lg-5 align-items-center justify-content-between">
                    <Col xs={12} lg={12} className="text-center text-lg-start">
                        <h1 className="ezy__httpcodes14-heading mb-3">Oh no! Error 500</h1>
                        <p className="ezy__httpcodes14-sub-heading mb-4">
                            Something went wrong, please try again or refresh the page!
                        </p>
                        <div className="d-flex justify-content-center justify-content-lg-start">
                            <Link to='/dashboard' className='btn btn-primary'>Return to Home</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default ErrorComponent;